import I18n from 'i18n-js';

export const sensor_preperation = (response, hide_null_values) => {
  let series_avg = [];
  let series_min = [];
  let series_max = [];
  let xaxis = [];
  response.data.map((datum) => {
    if (!hide_null_values) {
      series_avg.push(datum.average);
      series_min.push(datum.min);
      series_max.push(datum.max);
      xaxis.push(datum.created_at);
    } else {
      if (datum.min !== null) {
        series_avg.push(datum.average);
        series_min.push(datum.min);
        series_max.push(datum.max);
        xaxis.push(datum.created_at);
      }
    }
  });

  return {
    type: 'date',
    max: series_max,
    avg: series_avg,
    min: series_min,
    xaxis: xaxis,
  };
};

export const sensor = (data, title, metric_type) => {
  const series = [
    {
      name: I18n.t('sites.dashboard.device.max'),
      data: data.max,
      // color: '#FF2626'
    },
    {
      name: I18n.t('sites.dashboard.device.average'),
      data: data.avg,
      // color: '#FECA21'
    },
    {
      name: I18n.t('sites.dashboard.device.min'),
      data: data.min,
      // color: '#1a77c6'
    },
  ];

  const options = {
    chart: {
      id: `sensor${Math.random()}`,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val !== null) {
          const value = metric_type === 'volt' ? 'v' : '°';
          return val + value;
        } else {
          return 'x';
        }
      },
      offsetY: 0,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: title,
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#dddddd', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    labels: data.xaxis ? data.xaxis : [],
    xaxis: {
      type: 'datetime',
      labels: {
        showDuplicates: false,
        format: 'dd.MM',
      },
    },
    tooltip: {
      x: {
        show: true,
        format: 'dd MMMM',
        formatter: undefined,
      },
    },
  };

  return {
    series,
    options,
  };
};
