import React, { useState } from 'react';
import Overview from './Overview';
import TenantDetails from './TenantDetails';
import TriggerConfigModal from './device/elements/TriggerConfigModal';
import QuitAlarmModal from './device/elements/QuitAlarmModal';
import AlarmInfoModal from './device/elements/AlarmInfoModal';

const TenantDashboard = ({ tenant_id, user_id }) => {
  const [triggerId, setTriggerId] = useState(0);
  const [alarm, setAlarm] = useState(0);
  return (
    <div>
      <div className="mb-2">
        <Overview tenant_id={tenant_id} level="tenant" />
      </div>
      <div>
        <TriggerConfigModal triggerId={triggerId} />
        <QuitAlarmModal triggerId={triggerId} userId={user_id} />
        <AlarmInfoModal alarm={alarm} />
        <TenantDetails tenant_id={tenant_id} setTriggerId={setTriggerId} setAlarm={setAlarm} />
      </div>
    </div>
  );
};

export default TenantDashboard;
