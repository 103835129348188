import { de, enGB } from 'date-fns/locale';

const languageWrapper = (locale) => {
  switch (locale) {
    case 'de':
      return de;
    case 'en':
      return enGB;
    default:
      return de;
  }
};

export default languageWrapper;
