import React, { useEffect, useState } from 'react';
import axios from 'axios';
import truncateText from '../../helpers/truncate_text';
import I18n from 'i18n-js';
import moment from 'moment/moment';
import inputClosed from '../../../../assets/icons/input_closed.svg';
import inputOpen2 from '../../../../assets/icons/input_open_2.svg';
import openInput from '../../../../assets/icons/open_input.svg';
import closeInput from '../../../../assets/icons/close_input.svg';
const ModularMetricsStatus = ({ tenant_id, customer_id, site_id, device_id, locale }) => {
  const [metrics, setMetrics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  // Switch language
  switch (locale) {
    case 'en':
      moment.locale('en');
      break;
    case 'de':
      moment.locale('de');
      break;
    default:
      moment.locale('en');
      break;
  }

  // Get metric data
  useEffect(() => {
    axios
      .get(Routes.metric_last_data_api_v1_device_path(device_id))
      .then((response) => {
        setMetrics(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Handle relay actions
  function relayOnHandleClick(metric_id) {
    axios.get(Routes.relay_on_api_v1_device_metric_path(device_id, metric_id)).then((response) => {
      setIsLoading(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  }

  function relayOffHandleClick(metric_id) {
    axios.get(Routes.relay_off_api_v1_device_metric_path(device_id, metric_id)).then((response) => {
      setIsLoading(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  }

  // Get background color
  const getBackgroundColor = (metric) => {
    const baseColorOn = '#28A745';
    const baseColorOff = '#D2122E';
    if (metric.type === 'input' || metric.type === 'relays') {
      return metric.value === 1
        ? metric.invert_input_visual
          ? baseColorOff
          : baseColorOn
        : metric.invert_input_visual
        ? baseColorOn
        : baseColorOff;
    } else {
      return I18n.t('devices.dashboard.no_data');
    }
  };

  // Show input states
  const input = (metric) => {
    let inputState;

    if (metric.value === 1) {
      inputState = <img src={inputOpen2} alt="SVG description" height="30" />;
    } else if (metric.value === 0) {
      inputState = <img src={inputClosed} alt="SVG description" height="10" />;
    } else {
      inputState = <span className="text-white text-uppercase">{metric.value}</span>;
    }

    return inputState;
  };

  // Show relay state and toggle state buttons
  const relay = (metric) => {
    return (
      <div className="container p-0">
        {isLoading ? (
          <span
            className="spinner-border spinner-border-sm"
            style={{ width: '1rem', height: '1rem', color: 'white' }}
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          <div className="row">
            {metric.value === 0 ? (
              <div
                className="col p-0 ps-1"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Tooltip on right"
                style={{ cursor: 'pointer' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => relayOnHandleClick(metric.id)}
              >
                <span className="text-white text-uppercase">
                  {isHovering ? (
                    <img src={openInput} alt="SVG description" height="33" />
                  ) : (
                    <i className="fas fa-angle-left mt-2"></i>
                  )}
                </span>
              </div>
            ) : (
              <div className="col p-0"></div>
            )}
            <div
              className="col p-0"
              style={{ marginTop: metric.value === 0 && '5px', marginBottom: metric.value === 0 && '5px' }}
            >
              <span className={isHovering ? 'visually-hidden' : ''}>{input(metric)}</span>
            </div>
            {metric.value === 1 ? (
              <div
                className="col p-0 pe-1"
                style={{ cursor: 'pointer' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => relayOffHandleClick(metric.id)}
              >
                <span className="text-white text-uppercase">
                  {isHovering ? (
                    <img src={closeInput} alt="SVG description" height="30" />
                  ) : (
                    <i className="fas fa-angle-right mt-2"></i>
                  )}
                </span>
              </div>
            ) : (
              <div className="col p-0"></div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Show sensor data with suffix
  const sensor = (metric) => {
    return (
      <span style={{ paddingLeft: '5px' }}>
        {metric.value}
        {metric.suffix} {metric.type === 'sensor' && <i className="fas fa-thermometer-half"></i>}
      </span>
    );
  };

  // Switch case for which metric
  const whichMetric = (metric) => {
    if (metric.value === 'null') {
      return I18n.t('devices.dashboard.no_data');
    }
    switch (metric.type) {
      case 'input':
        return input(metric);
      case 'relays':
        return relay(metric);
      case 'sensor':
      case 'volt':
        return sensor(metric);
      default:
        return metric.value;
    }
  };

  return (
    <div className="metric-status-parent">
      {metrics.no_data ? (
        <h1>{I18n.t('devices.dashboard.no_data')}</h1>
      ) : (
        metrics.map((metric) => {
          return (
            <div key={metric.id} className="metric-status-child card" style={{ textAlign: 'center' }}>
              <div
                className="card-header"
                style={{ fontSize: '85%', backgroundColor: '#0061AA', color: 'white' }}
                data-toggle="tooltip"
                data-placement="top"
                title={metric.name ? metric.name : metric.accessor}
              >
                <a
                  href={Routes.general_tenant_customer_metric_path(
                    tenant_id,
                    customer_id,
                    site_id,
                    device_id,
                    metric.id
                  )}
                  style={{ color: 'white' }}
                >
                  {metric.name ? truncateText(metric.name, 15) : truncateText(metric.accessor, 15)}
                </a>
              </div>
              <div
                className="card-body"
                style={{
                  padding: '5px',
                  textAlign: 'center',
                  backgroundColor: getBackgroundColor(metric),
                }}
              >
                {whichMetric(metric)}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
export default ModularMetricsStatus;
