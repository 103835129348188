import React from 'react';
import Table from '../Table';
import moment from 'moment';
import { defineAbilitiesFor } from '../../utils';

const Users = ({ data, show_hidden, tenant_id, customer_id, site, new_on_index }) => {
  const ability = defineAbilitiesFor(window.Abilities);

  // Functions
  const handleShowUser = ({ original }) => {
    window.location.replace(Routes.general_tenant_customer_user_path(tenant_id, customer_id, site.id, original.id));
  };

  const handleDeleteUser = ({ original }) => {
    Swal.fire({
      title: I18n.t('users.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d90d0d',
      confirmButtonText: I18n.t('users.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('users.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_user_path(tenant_id, site.customer_id, original.site_id, original.id),
          type: 'DELETE',
        });
      }
    });
  };

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('users.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('users.index.email'),
      accessor: 'email',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('users.index.permission_role'),
      accessor: 'role',
      filter: 'fuzzyText',
      Cell: ({ row }) => {
        let span = null;
        switch (row.original.role) {
          case 'user':
            span = <span className="user">{I18n.t('users.role.user')}</span>;
            break;
          case 'site_admin':
            span = <span className="account-admin">{I18n.t('users.role.site_admin')}</span>;
            break;
          case 'customer_admin':
            span = <span className="customer-admin">{I18n.t('users.role.customer_admin')}</span>;
            break;
          case 'tenant_admin':
            span = <span className="tenant-admin">{I18n.t('users.role.tenant_admin')}</span>;
            break;
          case 'super_admin':
            span = <span className="super-admin">{I18n.t('users.role.super_admin')}</span>;
            break;
          default:
            span = null;
        }

        return <div className="badge badge-dark permission-roles">{span}</div>;
      },
    },
    {
      Header: I18n.t('users.index.last_sign_in_at'),
      accessor: 'last_sign_in_at',
      Cell: ({ row }) =>
        row.original.last_sign_in_at ? <span>{moment(row.original.last_sign_in_at).format('lll')}</span> : null,
    },
    {
      Header: I18n.t('users.index.sign_in_count'),
      accessor: 'sign_in_count',
    },
    {
      Header: I18n.t('users.index.ip_adress'),
      accessor: 'last_sign_in_ip',
    },
    {
      Header: I18n.t('users.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small user-general"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowUser(row)}
          >
            <i className="fa fa-wrench btn-small"></i>
          </button>
          <button
            className="btn btn-small btn-caution"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleDeleteUser(row)}
            disabled={!ability.can('destroy', 'User')}
          >
            <i className="fa fa-trash btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} newOnIndex={new_on_index} />;
};

export default Users;
