import React from 'react';

const MissedAlarmsButton = () => {
  return (
    <div>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle text-white"
          role="button"
          data-bs-toggle="modal"
          data-bs-target="#showMissedAlarms"
        >
          <i style={{ color: 'orange' }} className="fas fa-exclamation-triangle pe-1"></i>
        </a>
      </li>
    </div>
  );
};

export default MissedAlarmsButton;
