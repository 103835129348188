import React, { useState } from 'react';
import axios from 'axios';
import openInput from '../../../assets/icons/open_input.svg';
import closeInput from '../../../assets/icons/close_input.svg';
import inputOpen2 from '../../../assets/icons/input_open_2.svg';
import inputClosed from '../../../assets/icons/input_closed.svg';

const RelaisToggle = ({ device_id, metric_id, metric_value, metric_invert_input_visual }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  function relayOnHandleClick() {
    axios.get(Routes.relay_on_api_v1_device_metric_path(device_id, metric_id)).then((response) => {
      setIsLoading(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  }

  function relayOffHandleClick() {
    axios.get(Routes.relay_off_api_v1_device_metric_path(device_id, metric_id)).then((response) => {
      setIsLoading(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  }

  const input = () => {
    let inputState;

    if (metric_value === 1) {
      inputState = <img src={inputOpen2} alt="SVG description" height="30" />;
    } else if (metric_value === 0) {
      inputState = <img src={inputClosed} alt="SVG description" height="10" />;
    } else {
      inputState = <span className="text-white text-uppercase">{metric_value}</span>;
    }

    return inputState;
  };

  const getBackgroundColor = () => {
    const baseColorOn = '#28A745';
    const baseColorOff = '#D2122E';
    return metric_value === 1
      ? metric_invert_input_visual
        ? baseColorOff
        : baseColorOn
      : metric_invert_input_visual
      ? baseColorOn
      : baseColorOff;
  };

  return (
    <div className="metric-status-parent">
      <div className="metric-status-child card" style={{ textAlign: 'center', maxWidth: '35%' }}>
        <div
          className="card-header"
          style={{ fontSize: '85%', backgroundColor: '#0061AA', color: 'white' }}
          data-toggle="tooltip"
          data-placement="top"
          title={'relay'}
        >
          Relay
        </div>
        <div
          className="card-body"
          style={{
            padding: '5px',
            textAlign: 'center',
            backgroundColor: getBackgroundColor(),
          }}
        >
          <div className="card-body container p-0">
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                style={{ width: '1rem', height: '1rem', color: 'white' }}
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <div className="row">
                {metric_value === 0 ? (
                  <div
                    className="col p-0 ps-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Tooltip on right"
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => relayOnHandleClick()}
                  >
                    <span className="text-white text-uppercase">
                      {isHovering ? (
                        <img src={openInput} alt="SVG description" height="33" />
                      ) : (
                        <i className="fas fa-angle-left mt-2"></i>
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="col p-0"></div>
                )}
                <div
                  className="col p-0"
                  style={{ marginTop: metric_value === 0 && '5px', marginBottom: metric_value === 0 && '5px' }}
                >
                  <span className={isHovering ? 'visually-hidden' : ''}>{input()}</span>
                </div>
                {metric_value === 1 ? (
                  <div
                    className="col p-0 pe-1"
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => relayOffHandleClick()}
                  >
                    <span className="text-white text-uppercase">
                      {isHovering ? (
                        <img src={closeInput} alt="SVG description" height="30" />
                      ) : (
                        <i className="fas fa-angle-right mt-2"></i>
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="col p-0"></div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RelaisToggle;
