import Table from '../indexes/Table';
import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import axios from 'axios';
import EmptyPage from '../../helpers/EmptyPage';
import DataReportInfoModal from './modals/DataReportInfoModal';

const Logs = (props) => {
  const { selectedDate, device_id, reportType, metrics, admin, locale } = props;

  const start_hour = 0o0;
  const end_hour = 23;
  let options = {
    // Date options used for filtering the alarms
    start_date: selectedDate,
    end_date: selectedDate,
    start_hour: start_hour,
    end_hour: end_hour,
    group_type: 'day',
    type: reportType,
  };

  const [reports, setReports] = useState([]);
  const [selectedDataReportId, setSelectedDataReportId] = useState([]);

  useEffect(() => {
    axios.get(Routes.data_logs_api_v1_device_path(device_id, options)).then((response) => {
      // Receive alarms data from rails api
      setReports(response.data);
    });
  }, [props.selectedDate, props.reportType]);

  const columns = React.useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      // Change header depending on report type
      Header:
        reportType === 'hourly'
          ? I18n.t('devices.logs.time')
          : reportType === 'daily'
          ? I18n.t('devices.logs.day')
          : reportType === 'weekly'
          ? I18n.t('devices.logs.week')
          : '',
      accessor: 'time',
    },
    {
      Header: I18n.t('devices.logs.report_type'),
      accessor: 'report_type',
    },
    {
      Header: I18n.t('devices.logs.created_at'),
      accessor: 'gw_created_at',
    },
    {
      Header: I18n.t('devices.logs.actions'),
      Cell: ({ row }) => (
        <span>
          <button
            className="btn btn-small"
            style={{}}
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#showDataReportInfoModal"
            onClick={() => {
              setSelectedDataReportId(row.original.id);
            }}
          >
            <i className="fas fa-info-circle"></i>
          </button>
        </span>
      ),
    },
  ]);

  return (
    <span>
      {reports.length > 0 ? (
        <span>
          <DataReportInfoModal
            device_id={device_id}
            dataReportId={selectedDataReportId}
            metrics={metrics}
            reportType={reportType}
            locale={locale}
          />
          <Table
            columns={columns}
            data={reports}
            hiddenColumns={admin ? [] : ['id']}
            newOnIndex={undefined}
            hideSearchBar={false}
            sortBy={'gw_created_at'}
          />
        </span>
      ) : (
        <EmptyPage icon={<i className="fas fa-database icon-empty"></i>} text={I18n.t('devices.logs.no_data')} />
      )}
    </span>
  );
};

export default Logs;
