import React from 'react';

const EmptyPage = ({ icon, text }) => {
  return (
    <div className="m-md-5 text-center">
      <span className="circle-dashboard-device shadow">{icon}</span>
      <br />
      <div className="dashboard-text">{text}</div>
    </div>
  );
};

export default EmptyPage;
