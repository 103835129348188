import React from 'react';

const Conditions = ({ conditions, trigger, updateTrigger }) => {
  return (
    <div>
      <div className="row" style={{ marginBottom: '10px' }}>
        <label className="form-check-label custom-label">{I18n.t('triggers.general.whenever')}</label>
        <span style={{ fontWeight: 'normal', fontSize: '0.8em', color: '#818181', marginBottom: '2px' }}>
          {I18n.t('triggers.general.define_alarm_condition')}
        </span>
      </div>
      <div className="row form-inline">
        {conditions.map((condition) => {
          return (
            <div className="col-sm" key={condition.value}>
              <div className="card shadow-light" style={{ marginBottom: '10px' }}>
                <div className="card-body" style={{ padding: '5px', minWidth: '125px' }}>
                  <div className="form-check custom-label">
                    <input
                      type="radio"
                      name="trigger[condition]"
                      id={`trigger_condition_${condition.name}`}
                      className="form-check-input"
                      style={{ marginBottom: '5px' }}
                      value={condition.name}
                      defaultChecked={condition.name.toString() === trigger.condition}
                      onChange={() => updateTrigger('condition', condition.name.toString())}
                    />
                    <label htmlFor={`trigger_condition_${condition.name}`} className="custom-label">
                      {condition.label}
                    </label>
                    {!['on', 'off'].includes(condition.name) && (
                      <div>
                        <label className="form-check-label" htmlFor={`trigger_condition_${condition.name}`}>
                          <div className="row" style={{ fontWeight: 'normal', fontSize: '0.8em', color: '#818181' }}>
                            <div className="col pe-1" dangerouslySetInnerHTML={{ __html: condition.icon }}></div>
                            <div className="col p-0">{I18n.t('triggers.general.threshold')}</div>
                          </div>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Conditions;
