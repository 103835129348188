import React, { useState } from 'react';
import Calendar from '../calendar/Calendar';
import Tabs from './Tabs';
import I18n from 'i18n-js';
import Logs from './Logs';
import Alarms from './Alarms';

const Log = ({ device_id, metrics, triggers, admin, locale }) => {
  // States and variables
  let today = new Date();
  const [selectedDate, setSelectedDate] = useState(
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
  );
  const [currentTab, setCurrentTab] = useState('1');
  const [reportType, setReportType] = useState('hourly');

  // Render
  return (
    <div>
      <div className="settings-box">
        <div className="card shadow-light">
          <div className="card-title">
            <div className="header">{I18n.t('devices.logs.logs')}</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-xl-4 calender-place">
                <label className="custom-label" style={{ marginBottom: '5px' }}>
                  {I18n.t('devices.logs.select_date')}
                </label>
                <Calendar setSelectedDate={setSelectedDate} locale={locale} />
                <br />
              </div>
              <div className="col-xl-5">
                <Tabs
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  reportType={reportType}
                  setReportType={setReportType}
                />
              </div>
            </div>
            <hr style={{ marginTop: '0' }} />
            <div>
              {currentTab === '1' ? (
                <Logs
                  selectedDate={selectedDate}
                  device_id={device_id}
                  reportType={reportType}
                  metrics={metrics}
                  admin={admin}
                  locale={locale}
                />
              ) : (
                <Alarms
                  selectedDate={selectedDate}
                  device_id={device_id}
                  triggers={triggers}
                  metrics={metrics}
                  admin={admin}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Log;
