import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import axios from 'axios';

const CommentsModal = ({ alarmId }) => {
  const [alarm, setAlarm] = useState({});

  useEffect(() => {
    if (alarmId) {
      axios.get(Routes.alarm_comments_api_v1_alarm_path(alarmId)).then((response) => {
        setAlarm(response.data);
      });
    }
  }, [alarmId]);

  const renderCommentsBefore = () => {
    return alarm.comments_before.map((comment, index) => (
      <span key={index}>
        <div className="row">
          <div className="col-6 text-center py-1">
            {comment.same_day ? <b>|</b> : <i className="fas fa-ellipsis-v"></i>}
          </div>
          <div className="col-6 text-center py-1">
            {comment.same_day ? <b>|</b> : <i className="fas fa-ellipsis-v"></i>}
          </div>
        </div>
        <details>
          <summary>
            <div className="row" style={{ marginLeft: '-35px' }}>
              <div className="col-6 text-center p-0">
                <i className="fas fa-comment"></i>{' '}
                {I18n.t('devices.logs.comment_modal.user_comment', { user: comment.user })}
              </div>
              <div className="col-6 text-center p-0">{comment.created_at}</div>
            </div>
          </summary>
          <div className="p-2">"{comment.description}"</div>
        </details>
      </span>
    ));
  };

  const renderClearedAt = () => {
    if (alarm.cleared_at) {
      return (
        <div className="row">
          <div className="col-6 text-center py-1">
            {alarm.same_day_as_last_comment ? <b>|</b> : <i className="fas fa-ellipsis-v"></i>}
          </div>
          <div className="col-6 text-center py-1">
            {alarm.same_day_as_last_comment ? <b>|</b> : <i className="fas fa-ellipsis-v"></i>}
          </div>
          <div className="col-6 text-center py-1">
            <i className="fas fa-check-circle text-success"></i> {I18n.t('devices.logs.comment_modal.alarm_finished')}
          </div>
          <div className="col-6 text-center py-1">{alarm.cleared_at}</div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12 text-center py-1">
          <i className="fas fa-ellipsis-v"></i>
        </div>
        <div className="col-12 text-center py-1">
          <i className="fas fa-times-circle text-danger"></i> {I18n.t('devices.logs.comment_modal.alarm_active')}
        </div>
      </div>
    );
  };

  const renderCommentsAfter = () => {
    return alarm.comments_after.map((comment, index) => (
      <span key={index}>
        <div className="row">
          <div className="col-6 text-center py-1">
            {comment.same_day ? <b>|</b> : <i className="fas fa-ellipsis-v"></i>}
          </div>
          <div className="col-6 text-center py-1">
            {comment.same_day ? <b>|</b> : <i className="fas fa-ellipsis-v"></i>}
          </div>
        </div>
        <details>
          <summary>
            <div className="row" style={{ marginLeft: '-35px' }}>
              <div className="col-6 text-center p-0">
                <i className="fas fa-comment"></i>{' '}
                {I18n.t('devices.logs.comment_modal.user_comment', { user: comment.user })}
              </div>
              <div className="col-6 text-center p-0">{comment.created_at}</div>
            </div>
          </summary>
          <div className="p-2">"{comment.description}"</div>
        </details>
      </span>
    ));
  };

  return (
    <div className="modal fade display-none" id="showCommentModal" tabIndex="-1">
      <div className="modal-dialog modal-lg" style={{ maxWidth: '600px' }}>
        <div className="modal-content rounded-1 shadow">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{alarm.title}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {alarm && alarm.comments_before && (
              <div>
                <span className="row">
                  <div className="col-6 text-center">
                    <i className="fas fa-exclamation-triangle text-danger"></i>{' '}
                    {I18n.t('devices.logs.comment_modal.alarm_received')}
                  </div>
                  <div className="col-6 text-center">{alarm.created_at}</div>
                </span>
                <span>{renderCommentsBefore()}</span>
                <span>{renderClearedAt()}</span>
                <span>{renderCommentsAfter()}</span>
              </div>
            )}
          </div>
          <div className="modal-footer" style={{ justifyContent: 'normal' }}>
            <div className="container text-center">
              <div className="row text-end">
                <div className="col text-end p-0">
                  <button type="button" className="btn btn-caution me-2" data-bs-dismiss="modal">
                    {I18n.t('devices.index.cancel')}
                  </button>
                  <button
                    className="btn btn-info me-2"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#showQuitAlarmModal"
                  >
                    <i className="fas fa-comment"></i> {I18n.t('devices.logs.comment_modal.add_comment')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsModal;
