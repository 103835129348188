import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const BarChart = ({ data, title }) => {
  const series = [
    {
      data: data.series ? data.series : [],
    },
  ];
  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '°';
      },
      offsetY: 0,
      style: {
        fontSize: '12px',
        colors: ['white'],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        color: 'red',
        dataLabels: {
          position: 'center', // top, center, bottom
        },
      },
    },
    fill: {
      colors: ['#FF0000'],
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: title,
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    labels: data.xaxis ? data.xaxis : [],
    xaxis: {
      type: data.type,
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd.MM',
          hour: 'HH:mm',
        },
      },
    },
  };
  return <ReactApexCharts options={options} series={series} type="bar" height="150" />;
};

export default BarChart;
