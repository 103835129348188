import React from 'react';
import I18n from 'i18n-js';

const UserProfile = (props) => {
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="accountSelectorDropdown"
        style={{ color: 'white' }}
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.current_user.email}
        <span style={{ paddingLeft: '10px' }}>
          <i className="fas fa-user"></i>
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="accountSelectorDropdown">
        {props.super_admin && props.tenant_id === 1 ? (
          <a onClick={() => showProfileSettings(props)} className="dropdown-item">
            {I18n.t('user_profile.profile')}
          </a>
        ) : null}
        {!props.super_admin ? (
          <a onClick={() => showProfileSettings(props)} className="dropdown-item">
            {I18n.t('user_profile.profile')}
          </a>
        ) : null}

        <a
          onClick={() => sign_out_current_user(props.current_user.sign_out_path, props.current_user.sign_in_path)}
          className="dropdown-item logout-button"
        >
          {I18n.t('user_profile.log_out')}
        </a>
      </div>
    </li>
  );
};

const sign_out_current_user = (sign_out_path, sign_in_path) => {
  Swal.fire({
    title: I18n.t('user_profile.question'),
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#d90d0d',
    confirmButtonText: I18n.t('user_profile.logout'),
    cancelButtonColor: '#0d69ad',
    cancelButtonText: I18n.t('user_profile.cancel'),
  }).then((result) => {
    if (result.value) {
      $.ajax({
        url: sign_out_path,
        type: 'DELETE',
        success: function (response) {
          window.location.href = sign_in_path;
        },
      });
    }
  });
};

const showProfileSettings = (props) => {
  window.location.replace(
    Routes.profile_tenant_customer_user_path(props.tenant_id, props.customer_id, props.site_id, props.current_user_id)
  );
};

export default UserProfile;
