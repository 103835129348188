import React from 'react';
import Table from '../Table';
import I18n from 'i18n-js';
import device from '../../dashboard/device/Device';
import moment from 'moment';

const RegisteredDevices = ({ data, show_hidden, new_on_index }) => {
  const parsed_data = JSON.parse(data);

  // Functions
  const handleShowDevice = ({ original }) => {
    window.location.replace(
      Routes.dashboard_tenant_customer_device_path(
        original.tenant_id,
        original.customer_id,
        original.site_id,
        original.device_id
      )
    );
  };

  const handleAssignRegisteredDevice = ({ original }) => {
    window.location.replace(Routes.tenant_assign_site_path(original.tenant_id, original.id));
  };

  const handleDestroyLink = ({ original }) => {
    Swal.fire({
      title: I18n.t('registered_devices.index.destroy_question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d90d0d',
      confirmButtonText: I18n.t('devices.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('devices.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_device_path(
            original.tenant_id,
            original.customer_id,
            original.site_id,
            original.device_id
          ),
          type: 'DELETE',
        });
      }
    });
  };
  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: I18n.t('registered_devices.index.serial_number'),
      accessor: 'serial_number',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('registered_devices.index.customer'),
      accessor: 'customer_name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('registered_devices.index.site'),
      accessor: 'site_name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('registered_devices.index.assigned_at'),
      accessor: 'assigned_at',
      filter: 'fuzzyText',
      Cell: ({ row }) =>
        row.original.assigned_at ? <span>{moment(row.original.assigned_at).format('lll')}</span> : null,
    },
    {
      Header: I18n.t('registered_devices.index.assigned'),
      accessor: 'device_id',
      filter: 'fuzzyText',
      Cell: ({ row }) => {
        let span = null;
        if (row.original.is_assigned) {
          span = <span className="user">{I18n.t('registered_devices.index.assigned')}</span>;
        } else {
          span = <span className="super-admin">{I18n.t('registered_devices.index.not_assigned')}</span>;
        }
        return <div className="badge badge-dark permission-roles">{span}</div>;
      },
    },
    {
      Header: I18n.t('customers.index.actions'),
      Cell: ({ row }) => (
        <div>
          {row.original.is_assigned ? (
            <div>
              <button
                className="btn btn-small device-logs"
                style={{ marginRight: '5px' }}
                onClick={(e) => handleShowDevice(row)}
                disabled={false}
              >
                <i className="fa fa-eye btn-small"></i>
              </button>
              <button
                className="btn btn-small btn-caution"
                style={{ marginRight: '5px' }}
                onClick={(e) => handleDestroyLink(row)}
                disabled={false}
              >
                <i className="fa fa-unlink btn-small"></i>
              </button>
            </div>
          ) : (
            <button
              className="btn btn-small"
              style={{ marginRight: '5px' }}
              onClick={(e) => handleAssignRegisteredDevice(row)}
            >
              <i className="fa fa-link btn-small"></i>
            </button>
          )}
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return (
    <Table columns={columns} data={parsed_data} hiddenColumns={!show_hidden ? ['id'] : []} newOnIndex={new_on_index} />
  );
};

export default RegisteredDevices;
