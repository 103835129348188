import React from 'react';

const SubCategory = ({ label, path, active, count }) => {
  return (
    <a href={path} className={`sidebar-sub-category-item ${active ? 'sidebar-sub-category-item-active' : null}`}>
      <div className="d-flex w-100">
        <span className="material-icons" style={{ paddingLeft: '5px', marginRight: '-10px' }}>
          {active ? 'keyboard_arrow_down' : 'navigate_next'}
        </span>
        <span className="label">{label}</span>
        <span className="ms-auto" style={{ paddingRight: '10px' }}>
          <span className="badge badge-custom">{count}</span>
        </span>
      </div>
    </a>
  );
};

export default SubCategory;
