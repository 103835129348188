import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const QuitAlarmModal = ({ triggerId, userId, showInfo = true }) => {
  const [comment, setComment] = useState('');
  const [alarm, setAlarm] = useState({});

  useEffect(() => {
    if (Number.isInteger(triggerId) && triggerId > 0) {
      axios.get(Routes.alarm_info_api_v1_trigger_path(triggerId)).then((response) => {
        setAlarm(response.data);
      });
    }
  }, [triggerId]);

  const changeComment = (comment) => {
    setComment(comment);
  };

  async function handleQuitAlarm(comment) {
    await axios({
      method: 'patch',
      url: Routes.quit_alarm_api_v1_trigger_path(triggerId),
      data: { quit_user_id: userId, comment: comment, alarm_id: alarm.id },
    })
      .then(() => {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className="modal fade display-none" id="showQuitAlarmModal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-1 shadow">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{I18n.t('devices.logs.missed_alarms_modal.add_comment')}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {alarm.has_comments && (
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  {I18n.t('devices.dashboard.quit_alarm_modal.last_comment')}
                </label>
                <div className="card">
                  <div className="card-header">
                    {alarm.last_comment.user} - {alarm.last_comment.created_at}
                  </div>
                  <div className="ps-3 py-1">{alarm.last_comment.description}</div>
                </div>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                {I18n.t('devices.dashboard.quit_alarm_modal.new_comment')}:
              </label>
              <textarea
                className="form-control"
                id="message-text"
                onChange={(e) => changeComment(e.target.value)}
                rows="7"
                maxLength="500"
              ></textarea>
            </div>
            <div>
              {comment.length < 10 ? (
                <label className="validation-text">{I18n.t('sites.dashboard.alarms.character_validation')}</label>
              ) : (
                <label style={{ color: comment.length > 450 ? 'orange' : null }}>
                  {I18n.t('sites.dashboard.alarms.characters_left', { characters_left: 500 - comment.length })}
                </label>
              )}
            </div>
          </div>
          <div className="modal-footer" style={{ justifyContent: 'normal' }}>
            <div className="container text-center">
              <div className="row justify-content-between">
                <div className="col text-start p-0">
                  {showInfo ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#showAlarmInfoModal"
                    >
                      {I18n.t('devices.dashboard.quit_alarm_modal.info')}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#showCommentModal"
                    >
                      {I18n.t('devices.dashboard.quit_alarm_modal.back')}
                    </button>
                  )}
                </div>
                <div className="col text-end p-0">
                  <button type="button" className="btn btn-caution me-2" data-bs-dismiss="modal">
                    {I18n.t('devices.index.cancel')}
                  </button>
                  {comment.length < 10 ? (
                    <button disabled type="button" className="btn btn-primary" onClick={() => handleQuitAlarm(comment)}>
                      {I18n.t('helpers.links.submit')}
                    </button>
                  ) : (
                    <button type="button" className="btn btn-light" onClick={() => handleQuitAlarm(comment)}>
                      {I18n.t('helpers.links.submit')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuitAlarmModal;
