import React from 'react';
import Table from '../Table';
import { original } from '@reduxjs/toolkit';
import { defineAbilitiesFor } from '../../utils';

const Metrics = ({ data, show_hidden, tenant_id, customer_id }) => {
  const ability = defineAbilitiesFor(window.Abilities);
  // Functions
  const handleShowMetric = ({ original }) => {
    window.location.replace(
      Routes.general_tenant_customer_metric_path(
        tenant_id,
        customer_id,
        original.site_id,
        original.device_id,
        original.id
      )
    );
  };

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: I18n.t('metrics.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
      Cell: ({ row }) => (
        <span className={row.original.active ? '' : 'disabled'}>
          {row.original.name.length ? row.original.name : row.original.accessor}
        </span>
      ),
    },
    {
      Header: I18n.t('metrics.index.accessor'),
      accessor: 'accessor',
    },
    {
      Header: I18n.t('metrics.index.active'),
      accessor: 'active',
      filter: 'fuzzyText',
      Cell: ({ row }) => (
        <span>
          {row.original.active ? (
            <span style={{ color: '#27A745' }}>
              <i className="fas fa-check-square"></i>
            </span>
          ) : null}
        </span>
      ),
    },
    {
      Header: I18n.t('metrics.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowMetric(row)}
            disabled={!ability.can('general', 'Metric')}
          >
            <i className="fa fa-wrench btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} />;
};

export default Metrics;
