import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CustomerDetails from './CustomerDetails';

const TenantDetails = ({ tenant_id, setTriggerId, setAlarm }) => {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    axios.get(Routes.customers_api_v1_tenant_path(tenant_id)).then((response) => {
      setCustomers(response.data);
    });
  }, []);

  return (
    <div>
      {customers.length > 0
        ? customers.map((customer, index) => {
            return (
              <details className="mb-2" key={index}>
                <summary style={customer.has_active_alarms ? { backgroundColor: 'orange' } : null}>
                  <div className="row">
                    <div className="col-7">
                      <i className="fas fa-user-friends"></i> {'  '}
                      <a
                        className="dashboard-link"
                        href={Routes.dashboard_tenant_customer_path(tenant_id, customer.id)}
                        style={{ color: 'black' }}
                      >
                        {customer.name}
                      </a>
                    </div>
                    <div className="col-5 text-end">
                      <div style={{ paddingRight: '16px' }}>
                        {customer.has_active_alarms ? (
                          <i
                            className="fas fa-exclamation-triangle"
                            style={{ paddingRight: '3px', fontSize: '14px', color: 'red' }}
                          ></i>
                        ) : (
                          ''
                        )}
                        <span className={customer.has_active_alarms ? 'me-2' : ''}>
                          <span style={{ color: 'darkred' }}>{customer.sites_alarm_count}</span> /{' '}
                          {customer.sites_count} <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </summary>
                <div className="row">
                  <CustomerDetails
                    key={index}
                    tenant_id={tenant_id}
                    customer_id={customer.id}
                    setTriggerId={setTriggerId}
                    setAlarm={setAlarm}
                  />
                </div>
              </details>
            );
          })
        : ''}
    </div>
  );
};
export default TenantDetails;
