import React, { Fragment } from 'react';
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';
import Pagination from './Pagination';

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, newOnIndex }) => {
  return (
    <div className="d-flex" style={{ margin: 0 }}>
      {newOnIndex}
      <button
        className="btn btn-small-custom btn-small-icon not-clickable index-search-button"
        style={{ height: '32px', borderRadius: '3px 0 0 3px' }}
      >
        <i className="fa fa-search" style={{ fontSize: '1em' }}></i>
      </button>
      <input
        id="index-search"
        autoFocus
        className="form-control"
        value={globalFilter || ''}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        style={{
          maxWidth: '250px',
          height: '32px',
          marginBottom: '10px',
          borderRadius: 0,
          paddingLeft: '10px',
        }}
      />
    </div>
  );
};

const ShowOnlyActiveFilter = ({ column: { filterValue, setFilter, filter } }) => {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${filter ? filter : ''}...`}
    />
  );
};

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

fuzzyTextFilterFn.autoRemove = (val) => !val;

const Table = ({ columns, data, hiddenColumns, newOnIndex, hideSearchBar, sortBy = 'id', desc = false }) => {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ShowOnlyActiveFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    state: { pageIndex, pageSize, globalFilter },
    pageCount,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{ id: sortBy, desc: desc }],
        pageSize: 20,
        hiddenColumns: hiddenColumns,
      },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <Fragment>
      {hideSearchBar ? undefined : (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          newOnIndex={<Markup content={newOnIndex} />}
        />
      )}
      <table id="index-table" className="table table-striped table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <Fragment key={i}>
                  <th className="th-border-right">
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={column.filterable ? { paddingTop: '5px', paddingBottom: '5px' } : null}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div className="pull-right">
                              <i className="fa fa-chevron-circle-down"></i>
                            </div>
                          ) : (
                            <div className="pull-right">
                              <i className="fa fa-chevron-circle-up"></i>
                            </div>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div>{column.filterable ? column.render('Filter') : null}</div>
                  </th>
                </Fragment>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length > 9 ? (
        <div className="text-center">
          <div className="pagination-display">
            <span className="me-2" style={{ marginTop: '7px' }}>
              <span className="data-count noselect rounded p-2">
                <i className="fas fa-bars pe-1 securus-blue"></i>
                {data.length}
              </span>
            </span>
            <Pagination
              currentPage={pageIndex === 0 ? 1 : pageIndex + 1}
              totalPages={pageCount}
              changeCurrentPage={(numPage) => gotoPage(numPage - 1)}
            />
            <select
              className="form-select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ width: '120px', height: '36px', borderRadius: 0, marginLeft: '10px' }}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default Table;
