import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import axios from 'axios';
import moment from 'moment';
import HourlyDailyGraphModal from './HourlyDailyGraphModal';
import WeeklyGraph from './WeeklyGraph';

const MetricModal = ({ device_id, dataReportId, selectedMetric, reportType, locale }) => {
  const [isSensor, setIsSensor] = useState(true);
  const [title, setTitle] = useState('metric');
  const [data, setData] = useState([]);
  const [metricType, setMetricType] = useState('');
  moment.locale(locale);

  useEffect(() => {
    const options = { metric: selectedMetric, report_id: dataReportId };
    axios.get(Routes.metric_data_api_v1_device_path(device_id, options)).then((response) => {
      setTitle(response.data.title);
      setIsSensor(response.data.isSensor);
      setMetricType(response.data.metric_type);
      setData(response.data.data);
    });
  }, [dataReportId, selectedMetric]);

  function isValidNumber(value) {
    // Checks if number is not a NaN or is an Integer
    if (isNaN(value)) {
      return false;
    }
    if (Number.isInteger(value)) {
      return true;
    }
    return typeof value === 'number' && !Number.isNaN(parseFloat(value));
  }

  function buildSensor() {
    // Builds the Apexchart
    try {
      if (data) {
        if (reportType === 'weekly' && data.type === 'sw') {
          return <WeeklyGraph data={data} isValidNumber={isValidNumber} metricType={metricType} />;
        } else if (data.type === 'snw') {
          return <HourlyDailyGraphModal data={data} isValidNumber={isValidNumber} metricType={metricType} />;
        }
      }
    } catch (err) {
      return <span>Error getting data</span>;
    }
  }

  function buildInput() {
    // Builds the inputs
    if (data && data.type === 'in') {
      return (
        <div className="p-2">
          {data.data.map((datum, index) => {
            return (
              <div className="border border-1 rounded-1 p-2 mb-2 row" key={index}>
                <div className="col-2 border-end text-center p-0">
                  <div>
                    {datum.time
                      ? moment(datum.time).format(reportType === 'weekly' ? 'ddd DD.MM - HH:mm' : 'HH:mm')
                      : ''}
                  </div>
                </div>
                <div className="col align-middle">
                  {datum.change === '1 --> 0'
                    ? I18n.t('devices.logs.metric_modal.input_one_to_zero')
                    : I18n.t('devices.logs.metric_modal.input_zero_to_one')}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <span>Error getting data</span>;
    }
  }

  function showContent() {
    switch (isSensor) {
      case true:
        return buildSensor();
      case false:
        return buildInput();
      default:
        return <span className="loading-text"></span>;
    }
  }

  return (
    <div className="modal fade display-none" id="showMetricModal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content rounded-1 shadow">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{title}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">{showContent()}</div>
          <div className="modal-footer justify-content-start">
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#showDataReportInfoModal">
              {I18n.t('devices.logs.metric_modal.back')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricModal;
