import consumer from './consumer';

$(document).on('turbolinks:load', function () {
  if ($('#dashboard_view').length > 0) {
    const element = document.getElementById('dashboard_view');
    const site_id = element.getAttribute('data-site-id');

    consumer.subscriptions.create(
      { channel: 'DashboardChannel', site_id: site_id },
      {
        connected() {
          console.log('connected');
        },

        disconnected() {
          console.log('disconnected');
        },

        received(data) {
          console.log(data);
          if (data.notification_type === 'new_alarm') {
            window.location.reload();
          }
        },
      }
    );
  }
});
