import React from 'react';
import Table from '../Table';
import { defineAbilitiesFor } from '../../utils';
import I18n from 'i18n-js';

const Tags = ({ data, show_hidden, tenant_id, customer_id, new_on_index }) => {
  const ability = defineAbilitiesFor(window.Abilities);

  // Functions
  const handleShowTag = ({ original }) => {
    window.location.replace(
      Routes.general_tenant_customer_tag_path(tenant_id, customer_id, original.site_id, original.id)
    );
  };

  const handleDeleteTag = ({ original }) => {
    Swal.fire({
      title: I18n.t('tags.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d90d0d',
      confirmButtonText: I18n.t('tags.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('tags.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_tag_path(tenant_id, customer_id, original.site_id, original.id),
          type: 'DELETE',
        });
      }
    });
  };

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('tags.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('tags.index.tag_type'),
      accessor: 'tag_type',
      filter: 'fuzzyText',
      Cell: ({ row }) => {
        let span = null;
        switch (row.original.tag_type) {
          case 'group_tag':
            span = <span className="group-tag">Group tag</span>;
            break;
          case 'filter_tag':
            span = <span className="filter-tag">Filter tag</span>;
            break;
          default:
            span = null;
        }

        return <div className="badge badge-dark tag-types">{span}</div>;
      },
    },
    {
      Header: I18n.t('tags.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowTag(row)}
            disabled={!ability.can('general', 'Tag')}
          >
            <i className="fa fa-wrench btn-small"></i>
          </button>
          <button
            className="btn btn-small btn-caution"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleDeleteTag(row)}
            disabled={!ability.can('destroy', 'Tag')}
          >
            <i className="fa fa-trash btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} newOnIndex={new_on_index} />;
};

export default Tags;
