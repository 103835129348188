import { SET_CURRENT_MONTH, SET_CURRENT_DAY } from '../actions/types';

const initialState = {
  locale: 'en',
  current_month: new Date(),
  current_day: null,
  current_slot: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_MONTH:
      return { ...state, current_month: action.payload, current_day: null };
    case SET_CURRENT_DAY:
      return { ...state, current_day: action.payload };
    default:
      return state;
  }
};

export default reducer;
