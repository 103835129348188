import React, { useState } from 'react';
import axios from 'axios';
import { csrfToken } from '@rails/ujs';
import Conditions from './Conditions';
import Arguments from './Arguments';
import RelayToggle from './RelayToggle';

const TriggerGeneral = ({ pathInfo, trigger, metrics, conditions, relayOptions }) => {
  const [metric, setMetric] = useState(metrics.find((metric) => metric.id === trigger.metric_id));
  const [formTrigger, setFormTrigger] = useState({
    name: trigger.name,
    active_tolerance_period: trigger.active_tolerance_period,
    inactive_tolerance_period: trigger.inactive_tolerance_period,
    condition: trigger.condition,
    delta_period: trigger.delta_period,
    threshold: trigger.threshold,
    toggle_relay: trigger.toggle_relay,
    metric_id: trigger.metric_id,
  });

  const handleSubmit = (event) => {
    // Save button
    event.preventDefault();
    axios
      .patch(
        Routes.general_tenant_customer_trigger_path(
          pathInfo.tenantId,
          pathInfo.customerId,
          pathInfo.siteId,
          pathInfo.deviceId,
          pathInfo.triggerId
        ),
        {
          trigger: formTrigger,
        },
        {
          headers: {
            'X-CSRF-Token': csrfToken(),
          },
        }
      )
      .then((response) => {
        window.location.replace(response.data.url);
      });
  };

  const updateTrigger = (attribute, newValue) => {
    // Updates the trigger useState
    setFormTrigger({ ...formTrigger, [attribute]: newValue });
  };

  const getConditions = () => {
    // Returns which conditions should be shown depending on the selected metric
    switch (metric.type) {
      case 'volt':
      case 'sensor':
        return conditions.filter((condition) => condition.name !== 'on' && condition.name !== 'off');
      case 'relays':
      case 'input':
        return conditions.filter((condition) => condition.name === 'on' || condition.name === 'off');
      default:
        return [];
    }
  };

  const changeMetric = (value) => {
    // Changes the selected metric
    const selectedMetric = metrics.find((metric) => metric.id === parseInt(value));
    setMetric(selectedMetric);
    updateTrigger('metric_id', selectedMetric.id);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-between">
          <div className="col-xl-5">
            <dl>
              <dd>
                <label className="custom-label" htmlFor="name">
                  {I18n.t('activerecord.attributes.trigger.name')}
                </label>
              </dd>
              <dd>
                <input
                  onChange={(e) => updateTrigger('name', e.target.value)}
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  defaultValue={trigger.name}
                />
              </dd>
              <dd>
                <label className="custom-label" htmlFor="metric_id">
                  {I18n.t('activerecord.attributes.trigger.metric_id')}
                </label>
              </dd>
              <dd>
                <select
                  id="metric_id"
                  name="metric_id"
                  className="form-select"
                  defaultValue={trigger.metric_id}
                  onChange={(e) => changeMetric(e.target.value)}
                >
                  {metrics.map((metric) => {
                    return (
                      <option key={metric.id} value={metric.id}>
                        {metric.name}
                      </option>
                    );
                  })}
                </select>
              </dd>
              <div className="settings-box">
                <div className="card shadow-light" style={{ marginTop: '10px' }}>
                  <div className="card-title">
                    <div className="header"> {I18n.t('triggers.general.conditions')}</div>
                  </div>
                  <div className="card-body">
                    <Conditions conditions={getConditions()} trigger={trigger} updateTrigger={updateTrigger} />
                    <Arguments
                      trigger={trigger}
                      metric={metric}
                      condition={formTrigger.condition}
                      updateTrigger={updateTrigger}
                    />
                    <RelayToggle trigger={trigger} relayOptions={relayOptions} updateTrigger={updateTrigger} />
                  </div>
                </div>
              </div>
            </dl>
          </div>
          <div className="col-2">
            <button className="pull-right btn btn-success" type="submit">
              {I18n.t('shared.buttons.save')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TriggerGeneral;
