import React from 'react';
import { format, isBefore, addMonths } from 'date-fns';
import languageWrapper from '../helpers/language_wrapper';
import classNames from 'classnames';
import styles from './Calendar.module.css';

const Header = (props) => {
  const dateFormat = 'MMMM yyyy';
  const new_date = addMonths(new Date(), -1);
  return (
    <div className={classNames(styles.header, styles.row, styles.row_middle, styles.drop_shadow)}>
      <div className={classNames(styles.col, styles.col_end)} onClick={props.prevMonth}>
        <div tabIndex="1" className={styles.icon}>
          <i className="fas fa-chevron-left" style={{ fontSize: '135%', paddingTop: '3px' }}></i>
        </div>
      </div>
      <div tabIndex="1" className={classNames(styles.col, styles.col_center)}>
        <span>{format(props.currentMonth, dateFormat, { locale: languageWrapper(props.locale) })}</span>
      </div>
      <div className={classNames(styles.col, styles.col_start)}>
        {isBefore(props.currentMonth, new_date) ? (
          <div tabIndex="1" className={styles.icon} onClick={props.nextMonth}>
            <i className="fas fa-chevron-right" style={{ fontSize: '135%', paddingTop: '3px' }}></i>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
