import React from 'react';

const RelayToggle = ({ trigger, relayOptions, updateTrigger }) => {
  return (
    <div>
      <hr style={{ marginTop: 0 }} />
      <div className="row" style={{ marginBottom: '10px' }}>
        <label className="form-check-label custom-label">{I18n.t('triggers.general.define_toggle_relay')}</label>
        <span
          style={{
            fontWeight: 'normal',
            fontSize: '0.8em',
            color: '#818181',
            marginBottom: '2px',
          }}
        >
          {I18n.t('triggers.general.define_toggle_relay_definition')}
        </span>
        <span
          style={{
            fontWeight: 'normal',
            fontSize: '0.8em',
            color: '#818181',
            marginBottom: '2px',
          }}
        >
          {I18n.t('triggers.general.define_toggle_relay_setting')}
        </span>
      </div>
      <div className="row form-inline">
        {relayOptions.map((setting, index) => (
          <div className="col-sm" key={index}>
            <div className="card shadow-light" style={{ marginBottom: '10px' }}>
              <div className="card-body" style={{ padding: '5px', minWidth: '185px' }}>
                <div className="form-check custom-label">
                  <input
                    type="radio"
                    id={`toggle_relay_${setting.value}`}
                    name="toggle_relay"
                    value={setting.value}
                    defaultChecked={trigger.toggle_relay === setting.value}
                    className="form-check-input"
                    onChange={(e) => updateTrigger('toggle_relay', e.target.value)}
                  />
                  <label className="form-check-label custom-label" htmlFor={`toggle_relay_${setting.value}`}>
                    {setting.label}
                    <div
                      style={{
                        fontWeight: 'normal',
                        fontSize: '0.8em',
                        color: '#818181',
                      }}
                    >
                      {setting.info}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelayToggle;
