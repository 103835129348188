import React from 'react';
import ReactApexCharts from 'react-apexcharts';
import { input } from './metric_type/InputHour';
import { sensor } from './metric_type/SensorHour';
import I18n from 'i18n-js';

const Chart = ({ data, title, metric_type }) => {
  let chart_data = {};
  let chart_type;
  switch (metric_type) {
    case 'sensor':
      chart_data = sensor(data, title);
      chart_type = 'line';
      break;
    case 'volt':
      chart_data = sensor(data, title);
      chart_type = 'line';
      break;
    case 'input':
      chart_data = input(data, title);
      chart_type = 'line';
      break;
    case 'relays':
      chart_data = input(data, title);
      chart_type = 'line';
      break;
    case 'status':
      chart_data = input(data, title);
      chart_type = 'line';
      return <div>{I18n.t('devices.logs.input')}</div>;
  }

  return <ReactApexCharts options={chart_data.options} series={chart_data.series} type={chart_type} />;
};

export default Chart;
