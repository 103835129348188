import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const HourlyDailyGraphModal = ({ data, isValidNumber, metricType }) => {
  const { data: chartData } = data;
  let min = chartData[0].data[0].y;
  let max = chartData[0].data[0].y;

  chartData.forEach((obj) => {
    obj.data.forEach((d) => {
      min = Math.min(min, d.y);
      max = Math.max(max, d.y);
    });
  });

  min = Math.floor(min) - 2;
  max = Math.ceil(max) + 2;
  const tickAmount = max - min;
  const options = {
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'HH:mm',
        datetimeUTC: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        text: I18n.t('shared.hours'),
      },
    },
    yaxis: {
      title: {
        text:
          metricType === 'volt'
            ? I18n.t('devices.logs.metric_modal.volt')
            : I18n.t('devices.logs.metric_modal.temperature'),
      },
      min: min,
      max: max,
      tickAmount: tickAmount,
      labels: {
        formatter: function (value) {
          return Math.floor(value).toString();
        },
        offsetX: -5,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: 'HH:mm',
      },
      y: {
        formatter: function (value) {
          if (isValidNumber(value)) {
            return value.toFixed(1).toString();
          } else {
            return value.toString();
          }
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    markers: {
      size: 1,
    },
  };

  return <ReactApexCharts series={data.data} options={options} />;
};

export default HourlyDailyGraphModal;
