import React from 'react';
import ReactApexCharts from 'react-apexcharts';
import I18n from 'i18n-js';

const LineChart = ({ data, title, type }) => {
  console.log('type', type);
  const series = [
    {
      name: I18n.t('sites.dashboard.device.average'),
      data: data.avg ? data.avg : [],
    },
    {
      name: I18n.t('sites.dashboard.device.min'),
      data: data.min ? data.min : [],
    },
    {
      name: I18n.t('sites.dashboard.device.max'),
      data: data.max ? data.max : [],
    },
  ];
  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '°';
      },
      offsetY: 0,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: title,
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    labels: data.xaxis,
    xaxis: {
      type: data.type,
    },
  };
  return <ReactApexCharts options={options} series={series} type="line" />;
};

export default LineChart;
