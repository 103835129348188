import React from 'react';

class Pagination extends React.Component {
  state = {
    firstThreeArray: [1],
    lastNumber: '',
    showEllipis: true,
  };
  componentDidMount() {
    if (this.props.totalPages <= 5) {
      var fArray = [];

      for (var i = 1; i <= this.props.totalPages; i++) {
        fArray.push(i);
      }

      this.setState({ firstThreeArray: fArray });
    } else {
      if (this.props.currentPage < 3) {
        this.setState({ firstThreeArray: [1, 2, 3] });
      } else {
        var fArray = [];
        var index = 1;
        for (let j = this.props.currentPage; j >= 0; j--) {
          fArray.push(j);
          if (index === 3) {
            break;
          }
          index++;
        }

        fArray.reverse();
        this.setState({ firstThreeArray: fArray });
      }
      this.setState({ lastNumber: this.props.totalPages });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.totalPages <= 5) {
      var fArray = [];

      for (var i = 1; i <= nextProps.totalPages; i++) {
        fArray.push(i);
      }
      this.setState({ firstThreeArray: fArray });
    } else {
      if (this.props.currentPage !== nextProps.currentPage || this.props.totalPages !== nextProps.totalPages) {
        if (nextProps.currentPage < 3) {
          this.setState({ firstThreeArray: [1, 2, 3] });
        } else {
          var fArray = [];
          fArray.push(nextProps.currentPage - 1);
          fArray.push(nextProps.currentPage);
          if (nextProps.currentPage + 1 < nextProps.totalPages) {
            fArray.push(nextProps.currentPage + 1);
          }
          if (
            nextProps.currentPage == nextProps.totalPages - 2 ||
            nextProps.currentPage == nextProps.totalPages - 1 ||
            nextProps.currentPage == nextProps.totalPages
          ) {
            this.setState({ showEllipis: false });
          } else {
            this.setState({ showEllipis: true });
          }
          this.setState({ firstThreeArray: fArray });
        }
        this.setState({ lastNumber: nextProps.totalPages });
      }
    }
  }
  first = () => {
    this.props.changeCurrentPage(1);
  };
  prev = () => {
    if (this.props.currentPage > 1) {
      this.props.changeCurrentPage(this.props.currentPage - 1);
    }
  };
  next = () => {
    if (this.props.currentPage < this.props.totalPages) {
      this.props.changeCurrentPage(this.props.currentPage + 1);
    }
  };
  changeCurrentPage = (no) => {
    this.props.changeCurrentPage(no);
  };
  showEllipsis = () => {
    if (this.state.showEllipis) {
      return (
        <li className="page-item">
          <a className="page-link noselect">...</a>
        </li>
      );
    }
  };
  isactive = (currentPage) => {
    if (this.props.currentPage == currentPage) {
      return true;
    }
    return false;
  };
  showLastPagi = () => {
    if (this.props.currentPage !== this.props.totalPages) {
      return (
        <li className="page-item">
          <a
            className={this.isactive(this.props.totalPages) ? 'page-link link-active noselect' : 'page-link noselect'}
            onClick={() => {
              this.changeCurrentPage(this.props.totalPages);
            }}
          >
            {this.props.totalPages}
          </a>
        </li>
      );
    }
  };
  showFirst = () => {
    if (this.props.currentPage > 1) {
      return (
        <li className="page-item">
          <a className="page-link noselect" onClick={this.first}>
            {I18n.t('helpers.pagination.first')}
          </a>
        </li>
      );
    } else {
      return (
        <li className="page-item">
          <a className="page-link noselect disabled" onClick={this.first}>
            {I18n.t('helpers.pagination.first')}
          </a>
        </li>
      );
    }
  };
  showPrev = () => {
    if (this.props.currentPage != 1) {
      return (
        <li className="page-item">
          <a className="page-link noselect" onClick={this.prev}>
            {I18n.t('helpers.pagination.previous')}
          </a>
        </li>
      );
    } else {
      return (
        <li className="page-item">
          <a className="page-link noselect disabled" onClick={this.prev}>
            {I18n.t('helpers.pagination.previous')}
          </a>
        </li>
      );
    }
  };
  showNext = () => {
    if (this.props.currentPage < this.props.totalPages) {
      return (
        <li className="page-item">
          <a className="page-link noselect" onClick={this.next}>
            {I18n.t('helpers.pagination.next')}
          </a>
        </li>
      );
    }
  };

  render() {
    return (
      <ul className="pagination">
        {this.showFirst()}
        {this.showPrev()}
        {this.props.totalPages <= 5 ? (
          this.state.firstThreeArray.map((no, index) => {
            return (
              <li key={index} className="page-item">
                <a
                  key={index}
                  className={
                    this.isactive(no)
                      ? 'page-link link-active noselect'
                      : this.props.currentPage === 0 && no === 1
                      ? 'page-link link-active noselect'
                      : 'page-link noselect'
                  }
                  onClick={() => {
                    this.changeCurrentPage(no);
                  }}
                >
                  {no}
                </a>
              </li>
            );
          })
        ) : (
          <React.Fragment>
            {this.state.firstThreeArray.map((no, index) => {
              return (
                <li key={index} className="page-item">
                  <a
                    key={index}
                    className={
                      this.isactive(no)
                        ? 'page-link link-active noselect'
                        : this.props.currentPage === 0 && no === 1
                        ? 'page-link link-active noselect'
                        : 'page-link noselect'
                    }
                    onClick={() => {
                      this.changeCurrentPage(no);
                    }}
                  >
                    {no}
                  </a>
                </li>
              );
            })}
            {this.showEllipsis()}
            {this.showLastPagi()}
          </React.Fragment>
        )}
        {this.showNext()}
      </ul>
    );
  }
}

export default Pagination;
