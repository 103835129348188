import React from 'react';
import Table from '../Table';
import { defineAbilitiesFor } from '../../utils';
import I18n from 'i18n-js';

const Sites = ({ data, show_hidden, new_on_index }) => {
  const ability = defineAbilitiesFor(window.Abilities);

  // Functions
  const handleSettingsAccount = ({ original }) => {
    window.location.replace(
      Routes.general_tenant_customer_site_path(original.tenant_id, original.customer_id, original.id)
    );
  };
  const handleShowAccount = ({ original }) => {
    window.location.replace(
      Routes.dashboard_tenant_customer_site_path(original.tenant_id, original.customer_id, original.id)
    );
  };

  const handleDeleteAccount = ({ original }) => {
    Swal.fire({
      title: I18n.t('sites.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d90d0d',
      confirmButtonText: I18n.t('sites.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('sites.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_site_path(original.tenant_id, original.customer_id, original.id),
          type: 'DELETE',
        });
      }
    });
  };

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('sites.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('sites.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small site-dashboard"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowAccount(row)}
          >
            <i className="fa fa-eye btn-small"></i>
          </button>
          <button
            className="btn btn-small site-general"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleSettingsAccount(row)}
          >
            <i className="fa fa-wrench btn-small"></i>
          </button>
          <button
            className="btn btn-small btn-caution"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleDeleteAccount(row)}
            disabled={!ability.can('destroy', 'Site')}
          >
            <i className="fa fa-trash btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} newOnIndex={new_on_index} />;
};

export default Sites;
