import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import axios from 'axios';

const TriggerConfigModal = ({ triggerId }) => {
  const [trigger, setTrigger] = useState(null);
  const [tenantId, setTenantId] = useState(0);
  const [customerId, setCustomerId] = useState(0);
  const [siteId, setSiteId] = useState(0);
  const [deviceId, setDeviceId] = useState(0);

  useEffect(() => {
    if (Number.isInteger(triggerId) && triggerId > 0) {
      axios
        .get(Routes.trigger_info_api_v1_tenant_path(triggerId), { params: { trigger_id: triggerId } })
        .then((response) => {
          setTrigger(response.data.trigger);
          setTenantId(response.data.tenant);
          setCustomerId(response.data.customer);
          setSiteId(response.data.site);
          setDeviceId(response.data.device);
        });
    }
  }, [triggerId]);

  return (
    <div className="modal fade display-none" id="showTriggerConfigModal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-1 shadow">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{trigger === null ? 'Loading' : trigger.name}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {trigger === null ? (
              'Loading'
            ) : (
              <table className="table">
                <tbody>
                  <tr>
                    <td>{I18n.t('devices.dashboard.trigger_config_modal.metric')}</td>
                    <td>{trigger.metric}</td>
                  </tr>
                  <tr>
                    <td>{I18n.t('devices.dashboard.trigger_config_modal.condition')}</td>
                    <td>{trigger.condition}</td>
                  </tr>
                  <tr>
                    <td>{I18n.t('devices.dashboard.trigger_config_modal.threshold')}</td>
                    <td>{trigger.threshold}</td>
                  </tr>
                  <tr>
                    <td>{I18n.t('devices.dashboard.trigger_config_modal.active_period')}</td>
                    <td>{trigger.alarm_period}</td>
                  </tr>
                  <tr>
                    <td>{I18n.t('devices.dashboard.trigger_config_modal.clear_period')}</td>
                    <td>{trigger.clear_period}</td>
                  </tr>
                  <tr>
                    <td>{I18n.t('devices.dashboard.trigger_config_modal.relay')}</td>
                    <td>{trigger.relay}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className="modal-footer">
            <button className="btn btn-caution" data-bs-dismiss="modal">
              {I18n.t('devices.index.cancel')}
            </button>
            <button
              className="btn"
              onClick={() => {
                window.location.replace(
                  Routes.general_tenant_customer_trigger_path(tenantId, customerId, siteId, deviceId, triggerId)
                );
              }}
            >
              {I18n.t('devices.dashboard.edit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TriggerConfigModal;
