import React from 'react';
import { format, startOfWeek, addDays } from 'date-fns';
import languageWrapper from '../helpers/language_wrapper';
import classNames from 'classnames';
import styles from './Calendar.module.css';

const Days = (props) => {
  const dateFormat = 'EEEEEE';
  const days = [];
  let startDate = startOfWeek(props.currentMonth, { locale: languageWrapper(props.locale) });
  for (let i = 0; i < 7; i++) {
    days.push(
      <div className={classNames(styles.col, styles.col_center)} key={i}>
        {format(addDays(startDate, i), dateFormat, { locale: languageWrapper(props.locale) })}
      </div>
    );
  }
  return <div className={classNames(styles.days, styles.row)}>{days}</div>;
};

export default Days;
