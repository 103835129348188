import React, { useState } from 'react';
import I18n from 'i18n-js';

const AlarmInfoModal = ({ alarm }) => {
  return (
    <div className="modal fade display-none" id="showAlarmInfoModal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-1 shadow">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{I18n.t('devices.dashboard.alarm_info_modal.alarm_info')}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: 'initial', color: 'initial' }}>{alarm.trigger_name}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{I18n.t('devices.dashboard.alarm_info_modal.alarm_at')}</td>
                  <td>{alarm.alarm_at}</td>
                </tr>
                <tr>
                  <td>{I18n.t('devices.dashboard.alarm_info_modal.value')}</td>
                  <td>{alarm.value}</td>
                </tr>
                <tr>
                  <td>{I18n.t('devices.dashboard.alarm_info_modal.condition')}</td>
                  <td>{alarm.condition}</td>
                </tr>
                {alarm.show_threshold && (
                  <tr>
                    <td>{I18n.t('devices.dashboard.alarm_info_modal.threshold')}</td>
                    <td>{alarm.threshold}</td>
                  </tr>
                )}
                <tr>
                  <td>{I18n.t('devices.dashboard.alarm_info_modal.metric')}</td>
                  <td>{alarm.metric}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer justify-content-start">
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#showQuitAlarmModal">
              {I18n.t('devices.dashboard.alarm_info_modal.comment')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmInfoModal;
