import React from 'react';
import moment from 'moment';
import I18n from 'i18n-js';

moment.locale('de');

const Status = ({ active_alarms, last_alarm, last_seen, last_refresh }) => {
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td style={{ width: '120x', fontWeight: 'bold' }}>{I18n.t('devices.dashboard.active_alarms')}</td>
          <td style={{ textAlign: 'right' }}>{active_alarms}</td>
        </tr>
        <tr>
          <td style={{ width: '120x', fontWeight: 'bold' }}>{I18n.t('devices.dashboard.last_seen_raw')}</td>
          <td style={{ textAlign: 'right' }}>
            {moment(last_seen).isValid() ? moment(last_seen).fromNow() : I18n.t('devices.dashboard.not_seen')}
          </td>
        </tr>
        <tr>
          <td style={{ width: '120x', fontWeight: 'bold' }}>{I18n.t('sites.dashboard.device.last_alarm')}</td>
          <td style={{ textAlign: 'right' }}>{last_alarm}</td>
        </tr>
        <tr>
          <td style={{ width: '120x', fontWeight: 'bold' }}>{I18n.t('devices.dashboard.last_refresh')}</td>
          <td style={{ textAlign: 'right' }}>{last_refresh}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Status;
