import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SiteDetails from './SiteDetails';

const CustomerDetails = ({ tenant_id, customer_id, setTriggerId, setAlarm }) => {
  const [sites, setSites] = useState([]);

  useEffect(() => {
    axios.get(Routes.sites_api_v1_tenant_path(tenant_id), { params: { customer_id: customer_id } }).then((response) => {
      setSites(response.data);
    });
  }, []);

  return (
    <div>
      {sites.length > 0
        ? sites.map((site, index) => {
            return (
              <div key={index} className="m-2">
                <details>
                  <summary
                    style={site.has_active_alarms ? { backgroundColor: 'orange' } : { backgroundColor: '#e6e6e6' }}
                  >
                    <div className="row">
                      <div className="col-7">
                        <i className="fas fa-map-marker-alt"></i>
                        {'  '}
                        <a
                          className="dashboard-link"
                          href={Routes.dashboard_tenant_customer_site_path(tenant_id, customer_id, site.id)}
                        >
                          {site.name} - <small>{site.location}</small>
                        </a>
                      </div>
                      <div className="col-5 text-end">
                        {site.has_active_alarms ? (
                          <i
                            className="fas fa-exclamation-triangle"
                            style={{ paddingRight: '3px', fontSize: '14px', color: 'red' }}
                          ></i>
                        ) : (
                          ''
                        )}
                        <span className={site.has_active_alarms ? 'me-2' : ''}>
                          <span style={{ color: 'darkred' }}>{site.devices_alarm_count} </span> / {site.devices_count}{' '}
                          <i className="fas fa-hdd"></i>
                        </span>
                      </div>
                    </div>
                  </summary>
                  <div>
                    <SiteDetails
                      tenant_id={tenant_id}
                      customer_id={customer_id}
                      site_id={site.id}
                      setTriggerId={setTriggerId}
                      setAlarm={setAlarm}
                      withTags={false}
                    />
                  </div>
                </details>
              </div>
            );
          })
        : ''}
    </div>
  );
};
export default CustomerDetails;
