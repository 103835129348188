import React from 'react';

const Item = ({ label, path, active }) => {
  return (
    <a href={path} className={`sidebar-subitem ${active ? 'sidebar-subitem-active' : null}`}>
      <div className="d-flex w-100">
        <span className="label">{label}</span>
        <span className="ms-auto" style={{ marginRight: '10px' }}>
          <i className="material-icons">format_list_bulleted</i>
        </span>
      </div>
    </a>
  );
};

export default Item;
