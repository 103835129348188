import React from 'react';

const Category = ({ label, icon, path, active, count }) => {
  return (
    <a href={path} className={`sidebar-item ${active ? 'sidebar-item-active' : null}`}>
      <div className="d-flex w-100">
        <span className="material-icons">{icon}</span>
        <span className="label">{label}</span>
        <span className="ms-auto">
          <span className="badge badge-custom">{count}</span>
        </span>
      </div>
    </a>
  );
};

export default Category;
