import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Device from './device/Device';

const SiteDetails = ({ tenant_id, customer_id, site_id, setTriggerId, setAlarm, withTags }) => {
  const [devices, setDevices] = useState([]);
  const [devicesWithoutTag, setDevicesWithoutTag] = useState([]);
  const [tags, setTags] = useState([]);
  const [devices_with_no_tag_have_alarm, setDevicesWithNoTagsHaveAlarm] = useState(false);

  useEffect(() => {
    axios.get(Routes.devices_api_v1_tenant_path(tenant_id), { params: { site_id: site_id } }).then((response) => {
      setDevices(response.data.devices);
      setDevicesWithoutTag(response.data.devices_without_tag);
      setTags(response.data.tags);
    });
  }, []);

  useEffect(() => {
    {
      devicesWithoutTag.map((device, index) => {
        if (device.has_active_alarms) {
          setDevicesWithNoTagsHaveAlarm(true);
        }
      });
    }
  }, [devicesWithoutTag]);

  const devicesWithoutTagComponent = (devices, tag_id = 0, omitText = false) => {
    return (
      <div className="row row-cols-1 row-cols-xl-2 row-cols-xxl-2 row-cols-xxxl-3 row-cols-xxxxl-4 p-2 pe-4 gy-3">
        {devices.length > 0 ? (
          devices.map((device, index) => {
            return (
              <div key={index} className="col pe-0">
                <Device
                  key={index}
                  tenant_id={tenant_id}
                  customer_id={customer_id}
                  site_id={site_id}
                  device_id={device.id}
                  setTriggerId={setTriggerId}
                  setAlarm={setAlarm}
                />
              </div>
            );
          })
        ) : omitText ? (
          ''
        ) : (
          <div className="col">
            {withTags ? (
              <span>
                {I18n.t('devices.dashboard.tag_no_devices')}
                <a href={Routes.devices_tenant_customer_tag_path(tenant_id, customer_id, site_id, tag_id)}>
                  {I18n.t('sites.dashboard.here')}
                </a>
              </span>
            ) : (
              I18n.t('devices.dashboard.site_no_devices')
            )}
          </div>
        )}
      </div>
    );
  };

  const devicesWithTagComponent = () => {
    return (
      <div>
        {tags.map((tag, index) => {
          return (
            <details className="mb-2" key={tag.id}>
              <summary style={tag.has_active_alarms ? { backgroundColor: 'orange' } : null}>
                <div className="row">
                  <div className="col-8">
                    <i className="fas fa-tag"></i>
                    {'  '}
                    <a
                      className="dashboard-link"
                      href={Routes.general_tenant_customer_tag_path(tenant_id, customer_id, site_id, tag.id)}
                    >
                      {tag.name}
                    </a>
                  </div>
                  <div className="col-4 text-end">
                    {tag.has_active_alarms ? (
                      <i
                        className="fas fa-exclamation-triangle"
                        style={{ paddingRight: '3px', fontSize: '14px', color: 'red' }}
                      ></i>
                    ) : (
                      ''
                    )}
                    <span className={tag.has_active_alarms ? 'me-2' : ''}>
                      <span style={{ color: 'darkred' }}>
                        {tag.devices.filter((device) => device.has_active_alarms).length}
                      </span>{' '}
                      / {tag.devices.length} <i className="fas fa-hdd"></i>
                    </span>
                  </div>
                </div>
              </summary>
              {devicesWithoutTagComponent(tag.devices, tag.id)}
            </details>
          );
        })}
        {devicesWithoutTag.length > 0 ? (
          <details className="mt-2">
            <summary style={devices_with_no_tag_have_alarm ? { backgroundColor: 'orange' } : null}>
              {I18n.t('sites.dashboard.tagless_devices')}
            </summary>
            {devicesWithoutTagComponent(devicesWithoutTag, undefined, true)}
          </details>
        ) : (
          ''
        )}
      </div>
    );
  };

  if (withTags) {
    return devicesWithTagComponent();
  } else {
    return devicesWithoutTagComponent(devices);
  }
};

export default SiteDetails;
