import React from 'react';

const ModalWindow = ({ show, children }) => {
  return (
    <div className={show ? 'modal display-block' : 'modal display-none'}>
      <section>{children}</section>
    </div>
  );
};
export default ModalWindow;
