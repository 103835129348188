import { Ability, AbilityBuilder } from '@casl/ability';

export const defineAbilitiesFor = (abilities) => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  abilities.forEach((item) => {
    item.actions.forEach((action) => {
      can(action, item.subjects[0]);
    });
  });

  // // can read blog posts
  // can('read', 'BlogPost');
  // // can manage (i.e., do anything) own posts
  // can('manage', 'BlogPost', { author: user.id });
  // // cannot delete a post if it was created more than a day ago
  // cannot('delete', 'BlogPost', {
  //   createdAt: { $lt: Date.now() - 24 * 60 * 60 * 1000 }
  // });

  return new Ability(rules);
};
