import React, { Component } from 'react';
import I18n from 'i18n-js';

class ResolvedSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
    };
  }

  handleChange = (e) => {
    this.setState({ selectedValue: e.target.value });
    this.props.filtered(parseInt(e.target.value), 2);
  };

  render() {
    return (
      <div>
        <select className="form-select form-select-sm" onChange={(e) => this.handleChange(e)}>
          <option key={'all_alarms'} value={0}>
            {I18n.t('sites.dashboard.device.tab_all')}
          </option>
          <option key={'resolved'} value={1}>
            {I18n.t('notification_mailer.send_alarm_resolved_notification.resolved')}
          </option>
          <option key={'resolved_not'} value={2}>
            {I18n.t('notification_mailer.send_alarm_resolved_notification.not_resolved')}
          </option>
        </select>
        {/*<h1>You chose {this.state.selectedValue}</h1>*/}
      </div>
    );
  }
}

export default ResolvedSelector;
