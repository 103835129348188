import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Status from './elements/Status';
import MetricsStatus from './elements/MetricsStatus';
import I18n from 'i18n-js';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';

const Device = ({ tenant_id, device_id, customer_id, site_id, setTriggerId, setAlarm }) => {
  const [value, setValue] = useState(0);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoadingRestart, setIsLoadingRestart] = useState(false);
  const [deviceConfig, setDeviceConfig] = useState([]);

  // Switch language
  switch (deviceConfig.locale) {
    case 'en':
      moment.locale('en');
      break;
    case 'de':
      moment.locale('de');
      break;
    default:
      moment.locale('en');
      break;
  }

  useEffect(() => {
    axios
      .get(Routes.device_info_api_v1_tenant_path(tenant_id), { params: { device_id: device_id } })
      .then((response) => {
        setDeviceConfig(response.data);
      });
  }, []);

  // Tabs code
  TabPanel.propTypes = {
    index: PropTypes.number,
    children: PropTypes.node,
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const isOnline = (device_is_online) => {
    switch (device_is_online) {
      case true:
        return <span className="text-capitalize">{I18n.t('devices.dashboard.online_status')}</span>;
      case false:
        return <span className="text-capitalize">{I18n.t('devices.dashboard.offline_status')}</span>;
      default:
        return <span className="text-capitalize">{I18n.t('devices.dashboard.error_status')}</span>;
    }
  };
  //---

  function showMuteAlarm({ last_alarm }) {
    axios
      .get(Routes.show_mute_alarm_api_v1_tenant_path(tenant_id), {
        params: { alarm_id: last_alarm.id, user_id: deviceConfig.user_id },
      })
      .then((response) => {
        window.open(response.data, '_blank').focus();
      });
  }

  function triggerAlarms() {
    return (
      <div style={{ margin: '-16px' }}>
        {deviceConfig.triggers.map((trigger) => {
          return (
            <div key={trigger.id}>
              <details style={{ boxShadow: 'none', border: 'none' }}>
                <summary style={{ backgroundColor: 'transparent' }}>
                  <div>
                    {trigger.name}{' '}
                    {trigger.has_active_alarm && (
                      <i
                        className="fas fa-exclamation-triangle pulsate-fwd"
                        style={{ paddingRight: '3px', fontSize: '14px', color: 'red' }}
                      ></i>
                    )}
                    {trigger.last_alarm.is_muted && (
                      <i
                        className="fas fa-volume-mute"
                        style={{ paddingRight: '3px', fontSize: '14px', color: 'black' }}
                      ></i>
                    )}
                  </div>
                </summary>
                <div className="card p-2" style={{ margin: '0 1rem 0 2.3rem', backgroundColor: 'whitesmoke' }}>
                  {trigger.has_active_alarm ? (
                    <div>
                      <span>{I18n.t('devices.dashboard.alarm_at', { alarm_at: trigger.last_alarm.alarm_at })}</span>
                      <br />
                      <div className="btn-group">
                        <button
                          className="btn"
                          onClick={() => {
                            showMuteAlarm(trigger);
                          }}
                        >
                          <i className="fas fa-volume-mute"></i> {I18n.t('public.alarms.mute_alarm.mute_alarm')}
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#showQuitAlarmModal"
                          onClick={() => setTriggerId(trigger.id)}
                        >
                          <i className="fas fa-comment"></i> {I18n.t('devices.dashboard.acknowledge')}
                        </button>
                        <button
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#showAlarmInfoModal"
                          onClick={() => setAlarm(trigger.last_alarm)}
                        >
                          <i className="fas fa-info-circle"></i> {I18n.t('devices.dashboard.view_info')}
                        </button>
                      </div>
                      <hr className="my-2" />
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    <span>{I18n.t('devices.dashboard.alarm_config')}</span>
                    <br />
                    <div>
                      <button
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#showTriggerConfigModal"
                        onClick={() => {
                          setTriggerId(trigger.id);
                        }}
                      >
                        <i className="fas fa-wrench"></i> {I18n.t('devices.dashboard.view_config')}
                      </button>
                    </div>
                  </div>
                </div>
              </details>
            </div>
          );
        })}
      </div>
    );
  }

  const goToDashboard = () => {
    window.location.replace(Routes.dashboard_tenant_customer_device_path(tenant_id, customer_id, site_id, device_id));
  };

  const refreshData = () => {
    setIsLoadingRefresh(true);
    axios.get(Routes.refresh_data_api_v1_device_path(device_id)).then((response) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };

  const restartDevice = () => {
    setIsLoadingRestart(true);
    axios.get(Routes.restart_device_api_v1_device_path(device_id)).then((response) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };

  return deviceConfig != [] ? (
    <div>
      <details style={{ minWidth: '463px' }} open={deviceConfig.has_active_alarms}>
        <summary
          className="device-name-in-tagless-devices"
          style={deviceConfig.has_active_alarms ? { backgroundColor: 'orange' } : null}
        >
          <div className="row">
            <div className="col-7" style={{ paddingLeft: '15px' }}>
              <i className="fas fa-hdd"></i>
              {'  '}
              <a
                id={'device_id_' + device_id}
                className="dashboard-link"
                href={Routes.dashboard_tenant_customer_device_path(tenant_id, customer_id, site_id, device_id)}
              >
                {deviceConfig.name}
              </a>
              {deviceConfig.is_in_low_usage && (
                <span className="dashboard-empty-link"> - {I18n.t('devices.general.low_data_mode')}</span>
              )}
            </div>
            <div className="col-5 text-end">
              {deviceConfig.has_active_alarms ? (
                <span>
                  <i
                    className="fas fa-exclamation-triangle pulsate-fwd"
                    style={{ paddingRight: '3px', fontSize: '14px', color: 'red' }}
                  ></i>
                </span>
              ) : (
                ''
              )}
              <span> {isOnline(deviceConfig.is_online)}</span>
            </div>
          </div>
        </summary>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label={I18n.t('sites.dashboard.device.tab_all')} {...a11yProps(0)} />
              <Tab
                label={
                  deviceConfig.has_active_alarms ? (
                    <span>
                      {I18n.t('sites.dashboard.device.tab_alarms')}{' '}
                      <i className="fas fa-exclamation-triangle" style={{ fontSize: '14px', color: 'red' }}></i>
                    </span>
                  ) : (
                    I18n.t('sites.dashboard.device.tab_alarms')
                  )
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* All */}
            <div style={{ margin: '-10px' }}>
              <div className="row">
                <div className="col-8 pe-0">
                  <Status
                    active_alarms={deviceConfig.active_alarms}
                    last_alarm={deviceConfig.last_alarm}
                    last_seen={deviceConfig.last_seen}
                    last_refresh={deviceConfig.last_refresh}
                  />
                </div>
                <div className="col-4 p-0 text-center">
                  <div className="btn-group-vertical h-100">
                    <button className="btn" onClick={() => goToDashboard()}>
                      <i className="fas fa-th-large"></i> {I18n.t('devices.dashboard.dashboard')}
                    </button>
                    <button className="btn" onClick={() => refreshData()} disabled={isLoadingRefresh}>
                      {isLoadingRefresh ? (
                        <span>
                          <span className="spinner-border spinner-border-sm"></span> Loading...
                        </span>
                      ) : (
                        <span>
                          <i className="fas fa-sync"></i> {I18n.t('devices.dashboard.refresh')}
                        </span>
                      )}
                    </button>
                    <button className="btn" onClick={() => restartDevice()} disabled={isLoadingRestart}>
                      {isLoadingRestart ? (
                        <span>
                          <span className="spinner-border spinner-border-sm"></span> Loading...
                        </span>
                      ) : (
                        <span>
                          <i className="fas fa-redo"></i> {I18n.t('devices.dashboard.restart')}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <details style={{ boxShadow: 'none', border: 'none' }}>
                <summary style={{ backgroundColor: 'transparent' }}>{I18n.t('devices.dashboard.metrics')}</summary>
                <div>
                  <MetricsStatus
                    locale={deviceConfig.locale}
                    tenant_id={tenant_id}
                    customer_id={customer_id}
                    site_id={site_id}
                    device_id={device_id}
                  />
                </div>
              </details>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* Alarms */}
            {deviceConfig.triggers ? (
              deviceConfig.triggers.length === 0 ? (
                <h3 key={device_id} style={{ margin: '-10px -5px' }}>
                  {I18n.t('sites.dashboard.device.no_alarms')}
                </h3>
              ) : (
                triggerAlarms()
              )
            ) : (
              ''
            )}
          </TabPanel>
        </Box>
      </details>
    </div>
  ) : (
    ''
  );
};

export default Device;
