import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../indexes/Table';
import EmptyPage from '../../helpers/EmptyPage';
import CommentsModal from './modals/CommentsModal';
import QuitAlarmModal from '../dashboard/device/elements/QuitAlarmModal';

const Alarms = (props) => {
  // Variables
  const start_hour = 0o0;
  const end_hour = 23;
  let options = {
    // Date options used for filtering the alarms
    start_date: props.selectedDate,
    end_date: props.selectedDate,
    start_hour: start_hour,
    end_hour: end_hour,
    group_type: 'day',
  };
  const [alarms, setAlarms] = useState([]); // Alarms data
  const [alarm, setAlarm] = useState(0); // Selected Alarm (used for comment modal)
  const [triggerId, setTriggerId] = useState(0); // Selected Alarm (used for comment modal)
  const [currentUserId, setCurrentUserId] = useState(0); // Selected Alarm (used for comment modal)

  // UseEffect
  useEffect(() => {
    axios.get(Routes.alarm_logs_api_v1_device_path(props.device_id, options)).then((response) => {
      // Receive alarms data from rails api
      setAlarms(response.data.alarms);
      setCurrentUserId(response.data.current_user_id);
    });
  }, [props.selectedDate]); // Only re-run if value changes

  // Render functions
  function returnOptions(filterValue, setFilter, filter, filteredData) {
    // Show select box for filtering trigger and metric
    return (
      <select
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        style={{ width: '100%', color: 'black' }}
        value={filterValue}
      >
        <option key="all" value="">
          {I18n.t('devices.logs.show_all')}
        </option>
        {filteredData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((filteredDatum, i) => (
            <option key={i} value={filteredDatum.name}>
              {filteredDatum.name}
            </option>
          ))}
      </select>
    );
  }

  const showComment = (alarm) => {
    // Show button for comment modal
    return (
      <span>
        <button
          className="btn btn-small"
          style={{ marginRight: '5px', marginTop: '-2px' }}
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#showCommentModal"
          onClick={() => {
            setAlarm(alarm);
            setTriggerId(alarm.trigger_id);
          }}
        >
          <i className="fas fa-comment btn-small"></i>
        </button>
      </span>
    );
  };

  // Columns
  const columns = React.useMemo(() => [
    {
      Header: I18n.t('devices.logs.id'),
      accessor: 'id',
    },
    {
      Header: I18n.t('devices.logs.alarm_at'),
      accessor: 'created_at',
    },
    {
      Header: I18n.t('devices.logs.value'),
      accessor: 'value',
    },
    {
      Header: I18n.t('devices.logs.trigger_name'),
      accessor: 'trigger_name',
      filterable: true,
      Filter: ({ column: { filterValue, setFilter, filter } }) =>
        returnOptions(filterValue, setFilter, filter, props.triggers),
    },
    {
      Header: I18n.t('devices.logs.metric_name'),
      accessor: 'metric',
      filterable: true,
      Filter: ({ column: { filterValue, setFilter, filter } }) =>
        returnOptions(filterValue, setFilter, filter, props.metrics),
    },
    {
      Header: I18n.t('devices.logs.comments'),
      accessor: 'comments',
      Cell: ({ row }) => (row.original.has_comments ? showComment(row.original) : ''),
    },
    {
      Header: I18n.t('devices.logs.active_time'),
      accessor: 'active_time',
    },
    {
      Header: I18n.t('devices.logs.cleared_at'),
      accessor: 'cleared_at',
    },
  ]);

  // Render
  return (
    <div>
      {alarms.length > 0 ? (
        <span>
          <CommentsModal alarmId={alarm ? alarm.id : null} />
          <QuitAlarmModal triggerId={triggerId} userId={currentUserId} showInfo={false} />
          <Table
            columns={columns}
            data={alarms}
            hiddenColumns={props.admin ? [] : ['id']}
            newOnIndex={undefined}
            hideSearchBar={false}
            sortBy={'created_at'}
            desc={true}
          />
        </span>
      ) : (
        <EmptyPage icon={<i className="fas fa-database icon-empty"></i>} text={I18n.t('devices.logs.no_data')} />
      )}
    </div>
  );
};

export default Alarms;
