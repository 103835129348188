import React, { Component } from 'react';
import I18n from 'i18n-js';

class TriggerSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
      triggers: [],
    };
  }

  componentDidMount() {
    const unique = [...new Map(this.props.dataAlarms.map((item) => [item['trigger_id'], item])).values()];
    this.setState({ triggers: unique });
  }

  handleChange = (e) => {
    this.setState({ selectedValue: e.target.value });
    this.props.filtered(parseInt(e.target.value), 1);
  };

  render() {
    return (
      <div>
        <select className="form-select form-select-sm" onChange={(e) => this.handleChange(e)}>
          <option key={'all_triggers'} value={-1}>
            {I18n.t('sites.dashboard.device.tab_all')}
          </option>
          {this.state.triggers.map((trigger) => (
            <option key={trigger.trigger_id} value={trigger.trigger_id}>
              {trigger.trigger_name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default TriggerSelector;
