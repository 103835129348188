import React from 'react';
import Table from '../Table';
import { defineAbilitiesFor } from '../../utils';
import I18n from 'i18n-js';
import moment from 'moment/moment';

const UnregisteredDevices = ({ data, show_hidden }) => {
  // Functions
  const handleShowUnregisteredDevice = ({ original }) => {
    window.location.replace(Routes.assign_tenant_path(original.id));
  };

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('unregistered_devices.serialnumber'),
      accessor: 'serial_number',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.index.created_at'),
      accessor: 'created_at',
      filter: 'fuzzyText',
      Cell: ({ row }) =>
        row.original.created_at ? <span>{moment(row.original.created_at).format('lll')}</span> : null,
    },
    {
      Header: I18n.t('triggers.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowUnregisteredDevice(row)}
          >
            <i className="fa fa-link btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} hideSearchBar={true} />;
};

export default UnregisteredDevices;
