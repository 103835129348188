import { SET_CURRENT_MONTH, SET_CURRENT_DAY } from './types';

export const setCurrentMonth = (currentMonth) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_MONTH,
      payload: currentMonth,
    });
  };
};

export const setCurrentDay = (currentDay) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_DAY,
      payload: currentDay,
    });
  };
};
