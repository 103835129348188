import React, { Fragment, useState } from 'react';
import Table from '../Table';
import I18n from 'i18n-js';
import moment from 'moment/moment';

const AllDevices = (data) => {
  const table_columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('devices.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.index.created_at'),
      accessor: 'created_at',
      Cell: ({ row }) =>
        row.original.created_at ? <span>{moment(row.original.created_at).format('lll')}</span> : null,
    },
    {
      Header: I18n.t('devices.all_devices.branch_version'),
      accessor: 'branch_version',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.all_devices.firmware_version'),
      accessor: 'firmware_version',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.all_devices.commits_behind'),
      accessor: 'commits_behind',
      filter: 'fuzzyText',
    },
  ]);

  return <Table columns={table_columns} data={data.data} hiddenColumns={[]} />;
};

export default AllDevices;
