import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const WeeklyGraph = ({ data, isValidNumber, metricType }) => {
  function getTick(min, max) {
    // Gets the resolution of the Y-Axis
    if (max - min > 30) {
      return 30;
    } else {
      return max - min;
    }
  }

  function getMinMaxValues(dataArray) {
    // Gets the minimum and maximum values of data
    if (dataArray) {
      let min = Infinity;
      let max = -Infinity;

      dataArray.forEach((obj) => {
        obj.data.forEach((value) => {
          if (value !== null) {
            min = Math.min(min, value);
            max = Math.max(max, value);
          }
        });
      });

      min = Math.floor(min) - 2;
      max = Math.ceil(max) + 2;

      return { min: min, max: max };
    } else {
      return { min: 0, max: 5 };
    }
  }

  const minMax = getMinMaxValues(data.data.yAxisData);
  const options = {
    xaxis: {
      type: 'category',
      categories: data.data.xAxisData,
      tooltip: {
        enabled: false,
      },
      title: {
        text: I18n.t('shared.days'),
      },
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      title: {
        text:
          metricType === 'volt'
            ? I18n.t('devices.logs.metric_modal.volt')
            : I18n.t('devices.logs.metric_modal.temperature'),
      },
      min: minMax.min,
      max: minMax.max,
      tickAmount: getTick(minMax.min, minMax.max),
      labels: {
        formatter: function (value) {
          return Math.floor(value).toString();
        },
        offsetX: -5,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: 'HH:mm',
      },
      y: {
        formatter: function (value) {
          if (isValidNumber(value)) {
            return value.toFixed(1).toString();
          } else {
            return value.toString();
          }
        },
      },
    },
    legend: {
      floating: true,
      position: 'top',
    },
    dataLabels: {
      enabled: true,
    },
    markers: {
      size: 1,
    },
  };

  return <ReactApexCharts series={data.data.yAxisData} options={options} />;
};

export default WeeklyGraph;
