import React from 'react';
import Table from '../Table';
import I18n from 'i18n-js';

const Tenants = ({ data, show_hidden, new_on_index }) => {
  // Functions
  const handleShowTenants = ({ original }) => {
    window.location.replace(Routes.general_tenant_path(original.id));
  };

  const handleDeleteTenant = ({ original }) => {
    Swal.fire({
      title: I18n.t('tenants.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'red',
      confirmButtonText: I18n.t('tenants.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('tenants.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_path(original.id),
          type: 'DELETE',
        });
      }
    });
  };

  function handleShowTenantDashboard({ original }) {
    window.location.replace(Routes.dashboard_tenant_path(original.id));
  }

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('tenants.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('tenants.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small tenant-dashboard"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowTenantDashboard(row)}
          >
            <i className="fa fa-eye btn-small"></i>
          </button>
          <button
            className="btn btn-small tenant-general"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowTenants(row)}
          >
            <i className="fa fa-wrench btn-small"></i>
          </button>
          <button
            className="btn btn-small btn-caution"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleDeleteTenant(row)}
          >
            <i className="fa fa-trash btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? [] : []} newOnIndex={new_on_index} />;
};

export default Tenants;
