import moment from 'moment';

export const input_preperation = (response) => {
  let series = [];
  let xaxis = [];
  if (response) {
    response.map((item) => {
      series.push(item.count_changes);
      xaxis.push(item.created_at);
    });
  }

  return {
    series: series,
    xaxis: xaxis,
  };
};

export const input = (data, title) => {
  const series = [
    {
      data: data.series ? data.series : [],
    },
  ];
  const options = {
    chart: {
      id: `input${Math.random()}`,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 0,
      style: {
        fontSize: '12px',
        colors: ['white'],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        color: 'red',
        dataLabels: {
          position: 'center', // top, center, bottom
        },
      },
    },
    fill: {
      colors: ['#3ca42b'],
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: title,
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    labels: data.xaxis ? data.xaxis : [],
  };

  return {
    series,
    options,
  };
};
