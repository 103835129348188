import React from 'react';

const AccountSelector = ({ sites }) => {
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        id="accountSelectorDropdown"
        style={{ color: 'white' }}
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {sites.filter((site) => site.active)[0].name}
        <span className="badge badge-light" style={{ marginLeft: '10px' }}>
          {sites.length}
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="accountSelectorDropdown">
        {sites.map((site) => (
          <a key={site.id} href={site.path} className={`dropdown-item ${site.active ? 'current-item' : null}`}>
            {site.name}
          </a>
        ))}
      </div>
    </li>
  );
};

export default AccountSelector;
