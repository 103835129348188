import React, { useState } from 'react';
import Table from '../Table';
import { defineAbilitiesFor } from '../../utils';
import moment from 'moment';
import I18n from 'i18n-js';

const Devices = ({ data, show_hidden, tenant_id, customer_id, new_on_index }) => {
  // Get abilities for current user
  const ability = defineAbilitiesFor(window.Abilities);
  // Functions
  const handleShowDevice = ({ original }) => {
    window.location.replace(
      Routes.dashboard_tenant_customer_device_path(tenant_id, customer_id, original.site_id, original.id)
    );
  };
  const handleSettingsDevice = ({ original }) => {
    window.location.replace(
      Routes.general_tenant_customer_device_path(tenant_id, customer_id, original.site_id, original.id)
    );
  };

  const handleDeleteDevice = ({ original }) => {
    Swal.fire({
      title: I18n.t('devices.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d90d0d',
      confirmButtonText: I18n.t('devices.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('devices.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_device_path(tenant_id, customer_id, original.site_id, original.id),
          type: 'DELETE',
        });
      }
    });
  };

  const handleRestoreDevice = ({ original }) => {
    Swal.fire({
      title: 'Do you really want to restore this device?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#0d69ad',
      confirmButtonText: 'Restore',
      cancelButtonColor: '#d90d0d',
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.restore_tenant_device_path(tenant_id, original.site_id, original.id),
          type: 'PATCH',
        });
      }
    });
  };

  // Device columns
  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('devices.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.index.tag'),
      accessor: 'tag',
      Cell: ({ row }) => (
        <div className="badge badge-dark permission-roles">
          <span className={row.original.has_tag ? 'user' : 'super-admin'}>{row.original.tag_name}</span>
        </div>
      ),
    },
    {
      Header: I18n.t('devices.index.created_at'),
      accessor: 'created_at',
    },
    {
      Header: I18n.t('devices.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small device-logs"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowDevice(row)}
            disabled={!ability.can('logs', 'Device')}
          >
            <i className="fa fa-eye btn-small"></i>
          </button>
          <button
            className="btn btn-small device-general"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleSettingsDevice(row)}
            disabled={!ability.can('general', 'Device')}
          >
            <i className="fa fa-wrench btn-small device-delete"></i>
          </button>
          <button
            className="btn btn-small btn-caution"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleDeleteDevice(row)}
            disabled={!ability.can('destroy', 'Device')}
          >
            <i className="fa fa-trash btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} newOnIndex={new_on_index} />;
};

export default Devices;
