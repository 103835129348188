import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import I18n from 'i18n-js';
import Table from '../indexes/Table';
import CommentOnAlarmModal from './CommentOnAlarmModal';
import CommentsHistoryModal from './CommentsHistoryModal';

const MissedAlarms = ({ tenant_id, user_role, user_id }) => {
  const [alarms, setAlarms] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [alarmId, setAlarmId] = useState(0);
  const [onlyActiveAlarms, setOnlyActiveAlarms] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios.get(Routes.missed_alarms_api_v1_tenant_path(tenant_id)).then((response) => {
      setAlarms(response.data.alarms);
      setCustomers(response.data.customers);
      setOnlyActiveAlarms(response.data.only_active_alarms);
      alarms.length < 1 && setNoData(true);
    });
  };

  function returnOptions(filterValue, setFilter, filter, filteredData) {
    // Show select box for filtering trigger and metric
    return (
      <select
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        style={{ width: '100%', color: 'black' }}
        value={filterValue}
      >
        <option key="all" value="">
          {I18n.t('devices.logs.show_all')}
        </option>
        {filteredData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((filteredDatum, i) => (
            <option key={i} value={filteredDatum.name}>
              {filteredDatum.name}
            </option>
          ))}
      </select>
    );
  }

  function showCommentsHistory({ original }) {
    const { id } = original;
    setAlarmId(id);
  }

  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('devices.logs.missed_alarms_modal.customer_name'),
      accessor: 'customer_name',
      filter: 'fuzzyText',
      filterable: true,
      Filter: ({ column: { filterValue, setFilter, filter } }) =>
        returnOptions(filterValue, setFilter, filter, customers),
    },
    {
      Header: I18n.t('devices.logs.missed_alarms_modal.site_name'),
      accessor: 'site_name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.logs.missed_alarms_modal.device_name'),
      accessor: 'device_name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.logs.missed_alarms_modal.trigger_name'),
      accessor: 'trigger_name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.logs.alarm_at'),
      accessor: 'created_at',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.logs.cleared_at'),
      accessor: 'cleared_at',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.logs.active_time'),
      accessor: 'active_for',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('devices.logs.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small customer-dashboard"
            style={{ marginRight: '5px' }}
            data-bs-toggle="modal"
            data-bs-target={row.original.has_comments ? '#showCommentsHistory' : '#showCommentOnAlarmModal'}
            onClick={(e) => showCommentsHistory(row)}
          >
            <i className="fa fa-comment btn-small"></i>
          </button>
          <button
            className="btn btn-small customer-dashboard"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowDevice(row)}
          >
            <i className="fa fa-eye btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);

  function handleMarkAsSeen() {
    axios.post(Routes.mark_alarms_as_seen_api_v1_tenant_path(tenant_id)).then(window.location.reload());
  }

  function getHiddenColumns() {
    switch (user_role) {
      case 'super_admin':
        return [];
      case 'tenant_admin':
        return ['id'];
      case 'customer_admin':
      case 'site_admin':
      case 'user':
        return ['id', 'customer_name'];
      default:
        return ['id', 'customer_name'];
    }
  }

  const handleShowDevice = ({ original }) => {
    window.location.replace(
      Routes.dashboard_tenant_customer_device_path(
        tenant_id,
        original.customer_id,
        original.site_id,
        original.device_id
      )
    );
  };

  const missedAlarmsModal = (
    <div className="modal fade display-none" id="showMissedAlarms" tabIndex="-1">
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
        style={{ maxWidth: '100%', width: alarms.length < 1 ? '50%' : '80%' }}
      >
        <div className="modal-content rounded-1 shadow">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{I18n.t('devices.logs.missed_alarms_modal.missed_alarms')}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {alarms.length < 1 ? ( // If there are no alarm
              noData ? ( // If even after request there are no alarms
                <div className="d-flex align-items-center justify-content-center">
                  <div className="container text-center">
                    <i className="fa fa-5x fa-check-circle text-success"></i>
                    <h2>{I18n.t('devices.logs.missed_alarms_modal.no_alarms')}</h2>
                  </div>
                </div>
              ) : (
                // If there is no data before the request
                <div className="text-center">
                  <h4 style={{ marginBottom: '-8px' }}>{I18n.t('devices.logs.missed_alarms_modal.button_info')}</h4>
                  <br />
                  <button className="btn mb-2" onClick={() => getData()} disabled={isLoading}>
                    {isLoading ? ( // If the request button has been pressed
                      <span>
                        <span className="spinner-border spinner-border-sm"></span> Loading...
                      </span>
                    ) : (
                      // Show request button
                      I18n.t('devices.logs.missed_alarms_modal.get_data')
                    )}
                  </button>
                </div>
              )
            ) : (
              // If there are active and missed alarms
              <Table
                columns={columns}
                data={alarms}
                hiddenColumns={getHiddenColumns()}
                newOnIndex={false}
                sortBy={'cleared_at'}
              />
            )}
          </div>
          <div className="modal-footer">
            <div className="container text-center">
              <div className="row justify-content-between">
                <div className="col text-end p-0">
                  <button type="button" className="btn btn-caution me-2" data-bs-dismiss="modal">
                    {I18n.t('devices.index.cancel')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleMarkAsSeen()}
                    data-bs-dismiss="modal"
                    disabled={onlyActiveAlarms}
                  >
                    {I18n.t('devices.logs.missed_alarms_modal.mark_all_as_seen')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <span>
      <CommentOnAlarmModal alarmId={alarmId} userId={user_id} showInfo={false} />
      <CommentsHistoryModal alarmId={alarmId} />
      {missedAlarmsModal}
    </span>
  );
};

export default MissedAlarms;
