import React from 'react';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  format,
  addDays,
  isSameMonth,
  isWeekend,
  isSameDay,
  isToday,
  toDate,
} from 'date-fns';
import languageWrapper from '../helpers/language_wrapper';
import styles from './Calendar.module.css';

const Cells = (props) => {
  const monthStart = startOfMonth(props.currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart, { locale: languageWrapper(props.locale) });
  const endDate = endOfWeek(monthEnd, { locale: languageWrapper(props.locale) });
  const dateFormat = 'dd';
  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = '';
  let hasFreeSlots = false;

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat, { locale: languageWrapper(props.locale) });
      const cloneDay = day;
      days.push(
        <div
          className={`${styles.col} ${styles.cell} ${styles.drop_shadow} ${
            !isSameMonth(day, monthStart)
              ? styles.disabled
              : isToday(day)
              ? styles.today
              : isToday(day) && !isSameDay(day, props.selectedDate)
              ? isWeekend(day)
                ? styles.working_weekend
                : styles.today
              : isSameDay(day, props.selectedDate)
              ? props.selected === 1
                ? null
                : styles.selected
              : ''
          }`}
          key={day}
          onClick={() => props.onDateClick(toDate(cloneDay))}
        >
          <span className={styles.number}>{formattedDate}</span>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className={styles.row} key={day}>
        {days}
      </div>
    );
    days = [];
  }
  return <div className={styles.body}>{rows}</div>;
};

export default Cells;
