$(document).on('turbolinks:load', () => {
  var tenant_id = $('#body').attr('tenant-id');
  var customer_id = $('#body').attr('customer-id');
  var site_id = $('#body').attr('site-id');

  $('#trigger_device_id').change((e) => {
    $.ajax({
      type: 'GET',
      url: Routes.metrics_tenant_customer_device_path(tenant_id, customer_id, site_id, e.target.value),
    });
  });

  $('#mini_view_select_metric').change((e) => {
    const device_id = e.target.value.split('_')[0];
    const metric_id = e.target.value.split('_')[1];
    $.ajax({
      type: 'GET',
      url: Routes.load_metric_tenant_customer_metric_path(tenant_id, customer_id, site_id, device_id, metric_id),
    });
  });

  $('#trigger_metric_id').change((e) => {
    const device_id = $('#trigger-conditional-device-id').attr('data-device-id');
    const trigger_id = $('#trigger-conditional-device-id').attr('data-trigger-id');
    const metric_id = e.target.value;
    $.ajax({
      type: 'GET',
      url: Routes.change_condition_view_tenant_customer_trigger_path(
        tenant_id,
        customer_id,
        site_id,
        device_id,
        trigger_id,
        {
          metric_id: metric_id,
        }
      ),
    });
  });

  $('#trigger_quittable').change((e) => {
    const device_id = $('#trigger-conditional-device-id').attr('data-device-id');
    const trigger_id = $('#trigger-conditional-device-id').attr('data-trigger-id');
    const state = $('#trigger_quittable').is(':checked');
    $.ajax({
      type: 'GET',
      url: Routes.show_clear_timer_tenant_customer_trigger_path(
        tenant_id,
        customer_id,
        site_id,
        device_id,
        trigger_id,
        { state: state }
      ),
    });
  });
});
