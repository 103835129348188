import React from 'react';
import Table from '../Table';
import { defineAbilitiesFor } from '../../utils';
import I18n from 'i18n-js';

const NotificationGroups = ({ data, show_hidden, tenant_id, customer_id, new_on_index }) => {
  const ability = defineAbilitiesFor(window.Abilities);

  // Functions
  const handleShowNotificationGroup = ({ original }) => {
    window.location.replace(
      Routes.general_tenant_customer_notification_group_path(tenant_id, customer_id, original.site_id, original.id)
    );
  };

  const handleDeleteNotificationGroup = ({ original }) => {
    Swal.fire({
      title: I18n.t('notification_groups.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d90d0d',
      confirmButtonText: I18n.t('notification_groups.index.delete'),
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('notification_groups.index.cancel'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_notification_group_path(tenant_id, customer_id, original.site_id, original.id),
          type: 'DELETE',
        });
      }
    });
  };

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: I18n.t('notification_groups.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('notification_groups.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowNotificationGroup(row)}
            disabled={!ability.can('general', 'NotificationGroup')}
          >
            <i className="fa fa-wrench btn-small"></i>
          </button>
          <button
            className="btn btn-small btn-caution"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleDeleteNotificationGroup(row)}
            disabled={!ability.can('destroy', 'NotificationGroup')}
          >
            <i className="fa fa-trash btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} newOnIndex={new_on_index} />;
};

export default NotificationGroups;
