import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Overview = ({ tenant_id, level }) => {
  const [alarmCount, setAlarmCount] = useState([
    {
      customers: 0,
      customers_alarm: 0,
      sites: 0,
      sites_alarm: 0,
      devices: 0,
      devices_alarm: 0,
    },
  ]);

  const overviewElements = [
    <span>{I18n.t('devices.dashboard.customers_alarm')}</span>,
    <span>
      {alarmCount.customers_alarm} / {alarmCount.customers}
    </span>,
    <span>{I18n.t('devices.dashboard.sites_alarm')}</span>,
    <span>
      {alarmCount.sites_alarm} / {alarmCount.sites}
    </span>,
    <span>{I18n.t('devices.dashboard.devices_alarm')}</span>,
    <span>
      {alarmCount.devices_alarm} / {alarmCount.devices}
    </span>,
  ];

  useEffect(() => {
    axios.get(Routes.overview_alarm_count_api_v1_tenant_path(tenant_id)).then((response) => {
      setAlarmCount(response.data);
    });
  }, []);

  switch (level) {
    case 'customer':
      overviewElements.splice(0, 2);
      break;
    case 'site':
      overviewElements.splice(0, 4);
      break;
  }

  function getClasses(index) {
    let classes = 'border';
    if (index === overviewElements.length - 1 || index === overviewElements.length - 2) {
      classes += index % 2 === 0 ? '' : ' border-start-0';
    } else {
      classes += index % 2 === 0 ? ' border-bottom-0' : ' border-start-0 border-bottom-0';
    }
    return classes;
  }

  return (
    <div>
      <div className="dashboard_overview_header">
        <span className="ps-2 fs-4 text-white text-decoration-none">{I18n.t('tenants.dashboard.overview.title')}</span>
      </div>
      <div className="rounded-bottom card shadow border" style={{ borderRadius: '0' }}>
        <div className="container ">
          <div className="row row-cols-2 text-center p-2">
            {overviewElements.map((element, index) => {
              return (
                <div key={index} className={`col p-2 ${getClasses(index)}`}>
                  {element}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
