import React, { Component } from 'react';

class MetricSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
    };
  }

  handleChange = (e) => {
    this.setState({ selectedValue: e.target.value });
    this.props.metricSelected(e.target.value);
  };

  render() {
    let active_metrics = [];
    this.props.metrics.map((metric) => (metric.active ? active_metrics.push(metric) : undefined));
    return (
      <div>
        <select
          id="logs-metric-selector"
          className="form-select form-select-sm metric-selector"
          onChange={(e) => this.handleChange(e)}
        >
          <option key={'all_metric'} value={-1}>
            {'alle'}
          </option>
          {this.props.metrics.map((metric, index) => (
            <option id={`metric-option-${index}`} key={metric.id} value={index} className={metric.id}>
              {metric.name ? metric.name : metric.accessor}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default MetricSelector;
