import React, { Component } from 'react';
import ReactApexCharts from 'react-apexcharts';

class HeatMapChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const series = [
      {
        name: 'Metric1',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric2',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric3',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric4',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric5',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric6',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric7',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric8',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: 'Metric9',
        data: generateData(18, {
          min: 0,
          max: 90,
        }),
      },
    ];
    const options = {
      chart: {
        height: 200,
        type: 'heatmap',
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Product Trends by Month',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    };
    return <ReactApexCharts options={options} series={series} type="heatmap" />;
  }
}

const generateData = (value, options) => {
  let data = [];
  for (let i = 0; i < value; i++) {
    data.push(Math.random() * (options.max - options.min) + options.min);
  }

  return data;
};

export default HeatMapChart;
