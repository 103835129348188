import React, { useState } from 'react';
import Logs from './Logs';
import Alarms from './Alarms';
import I18n from 'i18n-js';
import triggers from '../indexes/triggers/Triggers';

const Tabs = (props) => {
  const { currentTab, setCurrentTab, setReportType } = props;

  const [reportTypeId, setReportTypeId] = useState('3');

  const log_type_tabs = [
    {
      id: 1,
      tabTitle: I18n.t('devices.logs.data'),
    },
    {
      id: 2,
      tabTitle: I18n.t('sites.dashboard.device.tab_alarms'),
    },
  ];

  const report_type_tabs = [
    {
      id: 3,
      tabTitle: I18n.t('devices.logs.hourly'),
      tabType: 'hourly',
    },
    {
      id: 4,
      tabTitle: I18n.t('devices.logs.daily'),
      tabType: 'daily',
    },
    {
      id: 5,
      tabTitle: I18n.t('devices.logs.weekly'),
      tabType: 'weekly',
    },
  ];

  const handleReportTypeTabClick = (tab) => {
    setReportTypeId(tab.id.toString());
    setReportType(tab.tabType);
  };

  return (
    <div>
      <label className="custom-label" style={{ marginBottom: '5px' }}>
        {I18n.t('devices.logs.select_logs')}
      </label>
      <div className="tabs">
        {log_type_tabs.map((tab, i) => (
          <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={() => setCurrentTab(`${tab.id}`)}>
            {tab.tabTitle}
          </button>
        ))}
      </div>
      {currentTab === '1' ? (
        <span>
          <br />
          <label className="custom-label" style={{ marginBottom: '5px' }}>
            {I18n.t('devices.logs.select_report')}
          </label>
          <div className="tabs mb-4">
            {report_type_tabs.map((tab, i) => (
              <button
                key={i}
                id={tab.id}
                disabled={reportTypeId === `${tab.id}`}
                onClick={() => handleReportTypeTabClick(tab)}
              >
                {tab.tabTitle}
              </button>
            ))}
          </div>
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default Tabs;
