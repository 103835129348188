import React from 'react';

const Arguments = ({ trigger, metric, condition, updateTrigger }) => {
  const threshold = (
    <div className="row" style={{ marginBottom: '5px' }}>
      <label className="form-check-label custom-label">{I18n.t('triggers.general.than')}</label>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginBottom: '2px',
        }}
      >
        {I18n.t('triggers.general.define_threshold_value')}
      </span>
      <dd>
        <input
          type="number"
          name="trigger[threshold]"
          defaultValue={trigger.threshold}
          onChange={(e) => updateTrigger('threshold', e.target.value)}
          className="form-control"
        />
      </dd>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginTop: '-22px',
        }}
      >
        {I18n.t('triggers.general.must_be_a_number')}
      </span>
    </div>
  );

  const activeTolerancePeriod = (
    <div className="row mb-2">
      <label className="form-check-label custom-label">{I18n.t('triggers.general.for_more_than')}</label>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginBottom: '2px',
        }}
      >
        {I18n.t('triggers.general.define_period_timespan')}
      </span>
      <dd>
        <input
          type="number"
          name="trigger[active_tolerance_period]"
          defaultValue={trigger.active_tolerance_period}
          onChange={(e) => updateTrigger('active_tolerance_period', e.target.value)}
          className="form-control"
          min={0}
        />
      </dd>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginTop: '-22px',
        }}
      >
        {I18n.t('triggers.general.must_be_a_number')}
      </span>
    </div>
  );

  const inactiveTolerancePeriod = (
    <div className="row mb-2">
      <label className="form-check-label custom-label">{I18n.t('triggers.general.automatically_clear')}</label>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginBottom: '2px',
        }}
      >
        {I18n.t('triggers.general.define_period_timespan')}
      </span>
      <dd>
        <input
          type="number"
          name="trigger[inactive_tolerance_period]"
          defaultValue={trigger.inactive_tolerance_period}
          onChange={(e) => updateTrigger('inactive_tolerance_period', e.target.value)}
          className="form-control"
          min={0}
        />
      </dd>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginTop: '-22px',
        }}
      >
        {I18n.t('triggers.general.must_be_a_number')}
      </span>
    </div>
  );

  const deltaPeriod = (
    <div className="row mb-2">
      <label className="form-check-label custom-label">{I18n.t('triggers.general.in')}</label>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginBottom: '2px',
        }}
      >
        {I18n.t('triggers.general.define_period_timespan')}
      </span>
      <dd>
        <input
          type="number"
          name="trigger[delta_period]"
          defaultValue={trigger.delta_period}
          onChange={(e) => updateTrigger('delta_period', e.target.value)}
          className="form-control"
          min={0}
        />
      </dd>
      <span
        style={{
          fontWeight: 'normal',
          fontSize: '0.8em',
          color: '#818181',
          marginTop: '-22px',
        }}
      >
        {I18n.t('triggers.general.must_be_a_number')}
      </span>
    </div>
  );

  if (metric.type === 'input' || metric.type === 'relays') {
    // If selected metric is an input
    return (
      <div>
        {activeTolerancePeriod}
        {inactiveTolerancePeriod}
      </div>
    );
  } else {
    if (condition === 'delta_rising' || condition === 'delta_falling') {
      // If selected condition is delta
      return (
        <div>
          {threshold}
          {deltaPeriod}
        </div>
      );
    } else {
      // If selected condition is not delta and metric is a sensor
      return (
        <div>
          {threshold}
          {activeTolerancePeriod}
          {inactiveTolerancePeriod}
        </div>
      );
    }
  }
};

export default Arguments;
