import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import { addMonths, subMonths } from 'date-fns';

import Header from './Header';
import Days from './Days';
import Cells from './Cells';
import styles from './Calendar.module.css';
import moment from 'moment';

class Index extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.ui.current_month !== this.props.ui.current_month) {
    }
  }
  onDateClick = (day) => {
    this.props.setSelectedDate(moment(day).format('YYYY-M-D'));
    this.props.setCurrentDay(day);
  };
  nextMonth = () => {
    this.props.setCurrentMonth(addMonths(this.props.ui.current_month, 1));
    this.props.setCurrentDay(null);
  };
  prevMonth = () => {
    this.props.setCurrentMonth(subMonths(this.props.ui.current_month, 1));
    this.props.setCurrentDay(null);
  };
  render() {
    const { ui } = this.props;
    const current_month = ui.current_month;
    const current_day = ui.current_day;
    return (
      <Fragment>
        <div className={`${styles.calendar} ${styles.drop_shadow}`}>
          <Header
            currentMonth={current_month}
            nextMonth={this.nextMonth}
            prevMonth={this.prevMonth}
            locale={this.props.locale}
          />
          <Fragment>
            <div className={styles.days_frame}>
              <Days currentMonth={current_month} locale={this.props.locale} />
            </div>
            <div className={styles.cells_frame}>
              <Cells
                currentMonth={current_month}
                selectedDate={current_day}
                onDateClick={this.onDateClick}
                availableDays={[]}
                locale={this.props.locale}
              />
            </div>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

export default connect(mapStateToProps, actions)(Index);
