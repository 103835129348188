import React from 'react';

const Item = ({ label, path, active, index }) => {
  return (
    <a href={path} className={`sidebar-subitem ${active ? 'sidebar-subitem-active' : null}`}>
      <div className="d-flex w-100">
        <span className="label">{label}</span>
      </div>
    </a>
  );
};

export default Item;
