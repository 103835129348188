import I18n from 'i18n-js';

export const sensor_preperation = (response, hide_null_values) => {
  let series_avg = [];
  let series_min = [];
  let series_max = [];
  let xaxis = [];
  response.map((item) => {
    if (item.max_value === '---') {
      series_max.push(0);
    } else {
      series_max.push(item.max_value);
    }
    if (item.min_value === '---') {
      series_min.push(0);
    } else {
      series_min.push(item.min_value);
    }
    if (item.average_value === '---') {
      series_avg.push(0);
    } else {
      series_avg.push(item.average_value);
    }
    xaxis.push(item.created_at);
  });
  return {
    type: 'hour',
    series: [
      {
        max: series_max,
        min: series_min,
        avg: series_avg,
      },
    ],
    xaxis: xaxis,
  };
};

export const sensor = (data, title) => {
  const series = [
    {
      name: I18n.t('sites.dashboard.device.max'),
      data: data.max,
      // color: '#FF2626'
    },
    {
      name: I18n.t('sites.dashboard.device.min'),
      data: data.min,
      // color: '#FF2626'
    },
    {
      name: I18n.t('sites.dashboard.device.average'),
      data: data.avg,
      // color: '#FF2626'
    },
  ];

  const options = {
    chart: {
      id: `sensor${Math.random()}`,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: title,
      align: 'left',
    },
    xaxis: {
      labels: {
        showDuplicates: false,
      },
    },
    tooltip: {
      x: {
        show: true,
        formatter: undefined,
      },
    },
  };

  return {
    series,
    options,
  };
};
