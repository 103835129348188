import React from 'react';

const Loading = ({ height }) => {
  return (
    <button className="btn btn-primary" style={{ height: height, width: '100%' }} type="button" disabled>
      <span
        className="spinner-border spinner-border-sm"
        style={{ width: '3rem', height: '3rem' }}
        role="status"
        aria-hidden="true"
      ></span>
      <div>Loading...</div>
    </button>
  );
};

export default Loading;
