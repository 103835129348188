import React from 'react';
import Table from '../Table';
import I18n from 'i18n-js';

const Customers = ({ data, show_hidden, new_on_index }) => {
  // Functions
  const handleShowCustomer = ({ original }) => {
    window.location.replace(Routes.dashboard_tenant_customer_path(original.tenant_id, original.id));
  };

  const handleSettingsCustomer = ({ original }) => {
    window.location.replace(Routes.general_tenant_customer_path(original.tenant_id, original.id));
  };

  const handleDeleteTenant = ({ original }) => {
    Swal.fire({
      title: I18n.t('customers.destroy.question'),
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#0d69ad',
      cancelButtonText: I18n.t('customers.index.cancel'),
      confirmButtonColor: 'red',
      confirmButtonText: I18n.t('customers.index.delete'),
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: Routes.tenant_customer_path(original.tenant_id, original.id),
          type: 'DELETE',
        });
      }
    });
  };

  // Account columns
  const columns = React.useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: I18n.t('customers.index.name'),
      accessor: 'name',
      filter: 'fuzzyText',
    },
    {
      Header: I18n.t('customers.index.actions'),
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-small customer-dashboard"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleShowCustomer(row)}
          >
            <i className="fa fa-eye btn-small"></i>
          </button>
          <button
            className="btn btn-small customer-general"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleSettingsCustomer(row)}
          >
            <i className="fa fa-wrench btn-small"></i>
          </button>
          <button
            className="btn btn-small btn-caution customer-delete"
            style={{ marginRight: '5px' }}
            onClick={(e) => handleDeleteTenant(row)}
          >
            <i className="fa fa-trash btn-small"></i>
          </button>
        </div>
      ),
      disableGlobalFilter: true,
    },
  ]);
  // Table renderer
  return <Table columns={columns} data={data} hiddenColumns={!show_hidden ? ['id'] : []} newOnIndex={new_on_index} />;
};

export default Customers;
