import React from 'react';

const SubCategoryItem = ({ label, path, active }) => {
  return (
    <a href={path} className={`sidebar-sub-category-subitem ${active ? 'sidebar-sub-category-subitem-active' : null}`}>
      <div className="d-flex w-100">
        <span className="label">{label}</span>
      </div>
    </a>
  );
};

export default SubCategoryItem;
