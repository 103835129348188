import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Index from './Index';

export default function Calendar(props) {
  return (
    <Provider store={store}>
      <Index setSelectedDate={props.setSelectedDate} locale={props.locale} />
    </Provider>
  );
}
