import React from 'react';

const SubCategoryItemIndex = ({ label, path, active, index }) => {
  return (
    <a
      href={path}
      className={`sidebar-sub-category-subitem sidebar-sub-category-subitem-index ${
        active ? 'sidebar-sub-category-subitem-index-active' : null
      }`}
    >
      <div className="d-flex w-100">
        <span className="label">{label}</span>
        <span className="ms-auto" style={{ marginRight: '10px' }}>
          <i className="material-icons">format_list_bulleted</i>
        </span>
      </div>
    </a>
  );
};

export default SubCategoryItemIndex;
